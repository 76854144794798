import { createStore, applyMiddleware } from 'redux';
import thunk from 'redux-thunk';
import { combineReducers } from '@reduxjs/toolkit';

import { companyRegisterReducer } from './companyRegisterReducer';
import { loginReducer } from './loginReducer';
import { authReducer } from './authReducer';
import { requestReducer } from './requestReducer';
import { companyOnboardReducer } from './companyOnboardReducer';
import { companyReducer } from './companyReducer';
import { invitationReducer } from './invitationReducer';
import { auditReportReducer } from './auditReportReducer';
import { userReducer } from './userReducer';

export const reducer = combineReducers({
  companyRegisterData: companyRegisterReducer,
  loginData: loginReducer,
  auth: authReducer,
  request: requestReducer,
  onboardCompany: companyOnboardReducer,
  company: companyReducer,
  invitation: invitationReducer,
  auditReport: auditReportReducer,
  user: userReducer
});

const store = createStore(reducer, applyMiddleware(thunk));
export default store;
