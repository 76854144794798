import httpUtil from '../helpers/interceptor';
import notify from '../helpers/notification';

export const fetchCompaniesExcelExport = async (body: {}) => {
  return httpUtil.post(`/api/am/dashboard/v1/get/AllCompanies`, body);
};

export const fetchCompanies = (body: {}, filters = []) => {
  return (dispatch: Function) => {
    dispatch({ type: 'GET_COMPANIES_LIST_START' });
    return httpUtil
      .post(`/api/am/dashboard/v1/get/AllCompanies`, body)
      .then((res) => {
        dispatch({
          type: 'GET_COMPANIES_LIST_COMPLETE',
          payload: { companies: res || [], filter: { ...body, filters } }
        });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching companies ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'GET_COMPANIES_LIST_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};

export const fetchCompaniesAccountApproval = (body: {} = {}, filters = []) => {
  return (dispatch: Function) => {
    dispatch({ type: 'GET_COMPANIES_ACCOUNT_APPROVAL_LIST_START' });
    return httpUtil
      .get(`/api/am/dashboard/v1/get/company/offboard/review`)
      .then((res) => {
        dispatch({
          type: 'GET_COMPANIES_ACCOUNT_APPROVAL_LIST_COMPLETE',
          payload: { data: res || [], filter: { ...body, filters } }
        });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching companies account approval ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'GET_COMPANIES_ACCOUNT_APPROVAL_LIST_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};

export const fetchCompaniesRevalidationList = (body: {} = {}, filters = []) => {
  return (dispatch: Function) => {
    dispatch({ type: 'GET_COMPANY_REVALIDATION_LIST_START' });
    return httpUtil
      .post(`/api/company/revalidation/v1/get/companyRevalidate`, body)
      .then((res) => {
        dispatch({
          type: 'GET_COMPANY_REVALIDATION_LIST_COMPLETE',
          payload: { data: res || [], filter: { ...body, filters } }
        });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching companies revalidation list ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'GET_COMPANY_REVALIDATION_LIST_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};
