import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import moment from 'moment';

import Loader from '../../atoms/Loader';
import httpUtil from '../../../helpers/interceptor';
import notify from '../../../helpers/notification';
import Dialog from '../../atoms/Dialog';
import {
  fetchProfile,
  fetchRequesterActiveRequest,
  fetchUserActiveRequest,
  fetchRequestHistory
} from '../../../actions';

export interface RequestDetailDialogProps {
  user: any;
  userTypes: any[];
  requestDetail: any;
  buttonTitle: string;
  open: boolean;
  onChange: Function;
  requestTableType: string;
  actualUserType: string[];
}

const RequestDetailDialog = ({
  user,
  userTypes = [],
  requestDetail,
  buttonTitle,
  open: isDialogOpen = false,
  onChange = () => {},
  requestTableType,
  actualUserType
}: RequestDetailDialogProps) => {
  const [open, setOpen] = useState(false);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [statusAction, setStatusAction] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [comment, setComment] = useState('');
  const [validation, setValidation] = useState<any>({});

  const {
    firstName,
    lastName,
    email,
    requestId,
    status,
    resourceName,
    type,
    createdDate,
    expiryDate,
    requestedBy,
    taskDetails = [],
    mcompanytype,
    mcocompany,
    mcouserorg,
    mcomultiorg,
    justification,
    approverHistory = [],
    supplierSiteId,
    address1,
    address2,
    city,
    state,
    country,
    postalCode,
    paList,
    pamList
  } = requestDetail;
  const dispatch = useDispatch();

  useEffect(() => {
    setOpen(isDialogOpen);
  }, [isDialogOpen]);

  useEffect(() => {
    setComment('');
    setValidation({});
    setConfirmDialogOpen(false);
  }, [open]);

  useEffect(() => {
    return () => {
      setComment('');
    };
  }, [requestDetail]);

  useEffect(() => {
    checkValidation(statusAction === 'APPROVE' || statusAction === 'DENY');
  }, [comment, statusAction]);

  const dialogOpen = () => {
    setOpen(true);
    if (onChange) onChange(true);
  };

  const statusUpdateDialogOpen = (action: string) => {
    if (checkValidation(action === 'APPROVE' || action === 'DENY')) {
      setStatusAction(action);
      setConfirmDialogOpen(true);
    }
  };

  const confirmDialogClose = () => {
    setConfirmDialogOpen(false);
  };

  const dialogClose = () => {
    setOpen(false);
    if (onChange) onChange(false);
  };

  const checkValidation = (isCommentRequired = false) => {
    let errors = { ...validation };
    let commentValue = comment?.trim();

    if (!commentValue && isCommentRequired) {
      errors.comment = 'Required';
    } else if (isCommentRequired && commentValue && commentValue.length < 25) {
      errors.comment = 'Comment must be at least 25 characters';
    } else if (isCommentRequired && commentValue && commentValue.length > 250) {
      errors.comment = "You can't write more than 250 characters";
    } else {
      delete errors.comment;
    }
    setValidation(errors);
    return Object.keys(errors).length === 0;
  };

  const updatedRequest = (action: string, requestUpdateData = {}) => {
    if (checkValidation(action === 'APPROVE' || action === 'DENY')) {
      let apiUrl = '';
      if (action === 'APPROVE') {
        apiUrl = `/api/v1/approval`;
      } else if (action === 'DENY') {
        apiUrl = `/api/v1/rejection`;
      } else if (action === 'CANCEL') {
        apiUrl = `/api/v1/user/profile/submit/cancel`;
      }

      if (action === 'CANCEL') {
        /* If user cancelled the request,need to hide profile submit success message in register form */
        dispatch({ type: 'PROFILE_SUBMIT_RESET' });
      }

      setIsLoading(true);
      return httpUtil
        .post(apiUrl, requestUpdateData)
        .then((res: any) => {
          setIsLoading(false);

          // dispatch({ type: 'UPDATE_REQUEST', payload: res });
          let actionSuccessText = '';
          let actionFailedText = '';
          switch (true) {
            case action === 'APPROVE':
              actionSuccessText = 'approved';
              actionFailedText = 'approving';
              break;

            case action === 'DENY':
              actionSuccessText = 'denied';
              actionFailedText = 'denying';
              break;

            case action === 'CANCEL':
              actionSuccessText = 'cancelled';
              actionFailedText = 'cancelling';
              break;

            default:
              actionSuccessText = 'updated';
              actionFailedText = 'updating';
              break;
          }
          const responseStatus = res?.status?.toLowerCase();
          if (responseStatus === 'success') {
            if (action === 'CANCEL') {
              dispatch(fetchProfile());
            }

            if (requestTableType == 'activeRequestorRequest') {
              dispatch(fetchRequesterActiveRequest());
            } else if (requestTableType == 'activeRequest') {
              dispatch(
                fetchUserActiveRequest({
                  userType: actualUserType
                    ? actualUserType?.map((item: string) => item?.toUpperCase())
                    : []
                })
              );
            } else if (requestTableType == 'requestHistory') {
              dispatch(
                fetchRequestHistory(
                  userTypes && userTypes.length ? userTypes[0] : ''
                )
              );
            }
            notify(
              `Request has been ${actionSuccessText} successfully`,
              'success'
            );
            setOpen(false);
            if (onChange) onChange(false);
          } else {
            notify(
              `An error occurred while ${actionFailedText} request, Please try again`,
              'error'
            );
          }
          return res;
        })
        .catch((error) => {
          setIsLoading(false);
          let errorMessage = '';
          if (error?.response?.status != 401) {
            errorMessage = `An error occurred while updating request ${
              error?.response?.data?.error
                ? ': ' + error?.response?.data?.error
                : ''
            }`;
            notify(errorMessage, 'error');
          }
        });
    }
  };

  const updateRequestClick = (action: string) => {
    const requestUpdateData: { reqId: number; comment?: string } = {
      reqId: requestId
    };

    if (action === 'APPROVE' || action === 'DENY') {
      requestUpdateData.comment = comment;
    }
    updatedRequest(action, requestUpdateData);
  };

  const statusSubmit = (event: any) => {};

  const onCommentChange = (event: any) => {
    setComment(event.target.value);
  };

  const isMyRequest = () => {
    if (requestedBy?.toLowerCase() === user?.ccoid?.toLowerCase()) {
      return true;
    }
    return false;
  };

  const isAllApproverApproved = () => {
    return (
      taskDetails?.length ===
      taskDetails?.filter?.(
        ({ status: taskStatus }: { status: string }) =>
          taskStatus?.toLowerCase() === 'approved'
      )?.length
    );
  };

  function isItemInArrayIgnoreCase(item: string, array: string[]) {
    const lowercasedItem = item?.toLowerCase();
    return array.some((element) => element?.toLowerCase() === lowercasedItem);
  }

  const isMyTaskPending = (approverRole: string) => {
    if (status?.toLowerCase() !== 'pending') {
      return false;
    }
    let isExist = false;
    taskDetails.forEach(
      ({
        taskName,
        status: taskStatus,
        approvers
      }: {
        taskName: string;
        status: string;
        approvers: string[];
      }) => {
        if (
          approverRole?.toLowerCase() === 'pa' &&
          taskName?.toLowerCase() === 'pa'
        ) {
          const userFound = paList?.some(
            (element: string) => element === user?.ccoid
          );

          if (userFound && taskStatus?.toLowerCase() === 'pending') {
            isExist = true;
          }
        }

        if (
          approverRole?.toLowerCase() === 'pam' &&
          taskName?.toLowerCase() === 'pam'
        ) {
          const userFound = pamList?.some(
            (element: string) => element === user?.ccoid
          );

          if (userFound && taskStatus?.toLowerCase() === 'pending') {
            isExist = true;
          }
        }
      }
    );
    return isExist;
  };

  const isPAApprovePending = () => {
    let isExist = false;
    taskDetails.forEach(
      ({ taskName, status }: { taskName: string; status: string }) => {
        if (
          taskName?.toLowerCase() === 'pa' &&
          status?.toLowerCase() === 'pending'
        ) {
          isExist = true;
        }
      }
    );
    return isExist;
  };

  const approvedDetails = (userType: string) => {
    let approvedDetailsData;
    approverHistory.forEach((item: any) => {
      if (userType?.toLowerCase() === item.approverType?.toLowerCase()) {
        approvedDetailsData = item;
      }
    });
    return approvedDetailsData;
  };

  return (
    <>
      <form name="statusform" onSubmit={statusSubmit}>
        {buttonTitle && <a onClick={dialogOpen}>{buttonTitle}</a>}
        <Dialog
          open={open}
          size="large"
          onDialogClose={dialogClose}
          closeOnClickOutside={false}
          title={`View Request : #${requestId}`}
          body={
            <div>
              {isLoading && (
                <div className="center-holder-wrap">
                  <Loader />
                </div>
              )}
              <div className="panel panel--bordered ">
                <div className="half-padding-left">
                  <h5>Request Details</h5>
                </div>
                <div className="row">
                  <div className="col-md-4">
                    <div className="">
                      <div className="responsive-table">
                        <table className="table table--compressed">
                          <tbody>
                            <tr>
                              <td>
                                <b>Request Id</b>
                              </td>
                              <td>{requestId}</td>
                            </tr>

                            <tr>
                              <td>
                                <b>Requestee Name</b>
                              </td>
                              <td>
                                {firstName} {lastName}
                              </td>
                            </tr>
                            <tr>
                              <td>
                                <b>Requestee Email</b>
                              </td>
                              <td>{email}</td>
                            </tr>

                            <tr>
                              <td style={{ width: '30%' }}>
                                <b>Request Status</b>
                              </td>
                              <td>{status}</td>
                            </tr>

                            {/* <tr>
                              <td>
                                <b>Requestee Id</b>
                              </td>
                              <td>{requestedBy}</td>
                            </tr> */}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                  <div className="col-md-4">
                    <div className="">
                      <div className="responsive-table">
                        <table className="table table--compressed">
                          <tbody>
                            <tr>
                              <td>
                                <b>Request Type</b>
                              </td>
                              <td>{type}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Requested Access</b>
                              </td>
                              <td>{resourceName}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Company Type</b>
                              </td>
                              <td>{mcompanytype}</td>
                            </tr>
                            <tr>
                              <td style={{ width: '30%' }}>
                                <b>Company Name</b>
                              </td>
                              <td>{mcocompany}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="">
                      <div className="responsive-table">
                        <table className="table table--compressed">
                          <tbody>
                            <tr>
                              <td>
                                <b>Primary Organization</b>
                              </td>
                              <td>{mcouserorg}</td>
                            </tr>

                            <tr>
                              <td>
                                <b>Secondary Organization</b>
                              </td>
                              <td>{mcomultiorg}</td>
                            </tr>

                            <tr>
                              <td>
                                <b>Request Date</b>
                              </td>
                              <td>
                                {moment(new Date(createdDate)).format(
                                  'MM-DD-YYYY'
                                )}
                              </td>
                            </tr>

                            <tr>
                              <td>
                                <b>Expiration Date</b>
                              </td>
                              <td>
                                {expiryDate
                                  ? moment(new Date(expiryDate)).format(
                                      'MM-DD-YYYY'
                                    )
                                  : '-'}
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="panel panel--bordered qtr-margin-top">
                <div className="row">
                  <div className="col-md-4">
                    <div className="">
                      <div
                        className="responsive-table"
                        style={{ width: '100%' }}
                      >
                        <table className="table table--compressed">
                          <tbody>
                            <tr>
                              <td>
                                <b>Address Line 1</b>
                              </td>
                              <td>{address1 || '-'}</td>
                            </tr>
                            <tr>
                              <td>
                                <b>Address Line 2</b>
                              </td>
                              <td>{address2 || '-'}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="">
                      <div className="responsive-table">
                        <table className="table table--compressed">
                          <tbody>
                            <tr>
                              <td style={{ width: '30%' }}>
                                <b>City</b>
                              </td>
                              <td>{city || '-'}</td>
                            </tr>

                            <tr>
                              <td>
                                <b>State</b>
                              </td>
                              <td>{state || '-'}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>

                  <div className="col-md-4">
                    <div className="">
                      <div className="responsive-table">
                        <table className="table table--compressed">
                          <tbody>
                            <tr>
                              <td style={{ width: '30%' }}>
                                <b>Country</b>
                              </td>
                              <td>{country || '-'}</td>
                            </tr>

                            <tr>
                              <td>
                                <b>Postal Code</b>
                              </td>
                              <td>{postalCode || '-'}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="panel panel--bordered qtr-margin-top">
                <div className="row">
                  <div className="col-md-12">
                    <div className="">
                      <div className="responsive-table">
                        <table className="table table--compressed">
                          <tbody>
                            <tr>
                              <td>
                                <b>Justification</b>
                              </td>
                              <td>{justification ? justification : '-'}</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className="panel panel--bordered qtr-margin-top">
                <div className="half-padding-left">
                  <h5>Approval Status</h5>
                </div>
                <div className="row">
                  <div className="col-md-12">
                    <div className="steps request-steps steps--primary">
                      {taskDetails &&
                        taskDetails.map(
                          (
                            {
                              taskName,
                              approvers,
                              status
                            }: {
                              taskName: string;
                              status: string;
                              approvers: any;
                            },
                            index: number
                          ) => {
                            const approvedUserDetails: any =
                              approvedDetails(taskName);
                            return (
                              <div
                                key={`task${taskName}`}
                                className={`step ${
                                  status?.toLowerCase() === 'pending' &&
                                  (taskName?.toLowerCase() === 'pa' ||
                                    (taskName?.toLowerCase() === 'pam' &&
                                      !isPAApprovePending()))
                                    ? 'visited'
                                    : ''
                                }  ${
                                  status?.toLowerCase() === 'approved'
                                    ? 'active'
                                    : ''
                                }`}
                                style={{
                                  width: `${100 / taskDetails.length}%`
                                }}
                              >
                                <div className={`step__icon`}>{taskName}</div>
                                {!(
                                  userTypes.includes('requester') &&
                                  taskName?.toLowerCase() === 'pam' &&
                                  isPAApprovePending()
                                ) && (
                                  <>
                                    <div className="step__label">
                                      {userTypes.includes('pa') &&
                                      isPAApprovePending() &&
                                      taskName?.toLowerCase() === 'pam'
                                        ? 'NOT INITIATED'
                                        : status}
                                    </div>
                                    {approvedUserDetails && (
                                      <div className="step_info half-margin-top bg-gray-300 text-left">
                                        <table className="table no-padding table--compressed">
                                          <tr>
                                            <td>
                                              <b>Approved By</b>
                                            </td>
                                            <td>
                                              {approvedUserDetails?.email}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <b>Date & Time</b>
                                            </td>
                                            <td>
                                              {approvedUserDetails?._id?.date
                                                ? moment(
                                                    new Date(
                                                      approvedUserDetails?._id?.date
                                                    )
                                                  ).format(
                                                    'MM-DD-YYYY, h:mm:ss A'
                                                  )
                                                : ''}
                                            </td>
                                          </tr>
                                          <tr>
                                            <td>
                                              <b>Comment</b>
                                            </td>
                                            <td>
                                              <div className="comment-wrap">
                                                {approvedUserDetails?.comment}
                                              </div>
                                            </td>
                                          </tr>
                                        </table>
                                      </div>
                                    )}

                                    <div className="step_info half-margin-top">
                                      {approvers
                                        ?.toString()
                                        ?.split(',')
                                        .map((item: string, i: number) => (
                                          <div
                                            key={`approvers${i}`}
                                            className="qtr-margin-top"
                                          >
                                            {item}
                                          </div>
                                        ))}
                                    </div>
                                  </>
                                )}
                              </div>
                            );
                          }
                        )}
                    </div>
                  </div>
                  {(isMyTaskPending('pa') || isMyTaskPending('pam')) && (
                    <div className="col-md-12">
                      <div
                        className={`form-group  ${
                          validation.comment ? 'form-group--error' : ''
                        }`}
                      >
                        <div className="form-group__text">
                          <textarea
                            id="approver-comment"
                            rows={2}
                            value={comment || ''}
                            onChange={onCommentChange}
                          ></textarea>
                          <label htmlFor="approver-comment">
                            <b>
                              Comment{' '}
                              {validation.comment && (
                                <>
                                  <span
                                    className="text-danger"
                                    title="this is a required field"
                                  >
                                    *
                                  </span>
                                  <span className="text-danger text-size-12">
                                    {' '}
                                    {validation.comment}
                                  </span>
                                </>
                              )}
                            </b>
                          </label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          }
          footer={
            isMyTaskPending('pa') || isMyTaskPending('pam') || isMyRequest() ? (
              <div>
                {isMyTaskPending('pa') || isMyTaskPending('pam') ? (
                  <>
                    <button
                      type="button"
                      onClick={() => statusUpdateDialogOpen('APPROVE')}
                      className="btn"
                      disabled={isLoading}
                    >
                      Approve
                    </button>
                    <button
                      type="button"
                      onClick={() => statusUpdateDialogOpen('DENY')}
                      className="btn btn--dark"
                      disabled={isLoading}
                    >
                      Deny
                    </button>
                  </>
                ) : null}

                {isMyRequest() &&
                  status?.toLowerCase() === 'pending' &&
                  !isAllApproverApproved() && (
                    <button
                      type="button"
                      onClick={() => statusUpdateDialogOpen('CANCEL')}
                      className="btn btn--dark"
                      disabled={isLoading}
                    >
                      Cancel Request
                    </button>
                  )}
              </div>
            ) : null
          }
        />
      </form>

      <Dialog
        open={confirmDialogOpen}
        size="small"
        onDialogClose={confirmDialogClose}
        closeOnClickOutside={false}
        title={`Confirm`}
        body={
          <div>
            <h4>
              Are you sure to{' '}
              <span style={{ textTransform: 'capitalize' }}>
                {statusAction?.toLowerCase()}
              </span>{' '}
              the Request #{requestId}
            </h4>
            {isLoading && (
              <div className="center-holder-wrap">
                <Loader />
              </div>
            )}
          </div>
        }
        footer={
          <div>
            <button
              type="button"
              onClick={confirmDialogClose}
              className="btn btn--ghost"
              disabled={isLoading}
            >
              Cancel
            </button>
            <button
              type="button"
              onClick={() => updateRequestClick(statusAction)}
              className="btn btn--primary"
              disabled={isLoading}
            >
              Confirm
            </button>
          </div>
        }
      />
    </>
  );
};

export default RequestDetailDialog;
