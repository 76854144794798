import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import Select from '../../molecules/Select';
import Loader from '../../atoms/Loader';
import httpUtil from '../../../helpers/interceptor';
import notify from '../../../helpers/notification';
import Dialog from '../../atoms/Dialog';
import { fetchProfile, fetchOrganization } from '../../../actions';

const OrgEditDialog = ({
  profile,
  buttonTitle,
  open: isDialogOpen = false,
  onChange = () => {}
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [value, setValue] = useState('');
  const [validation, setValidation] = useState({});
  const [isSubmitted, setIsSubmitted] = useState(false);

  const { mcouserorg, mcomultiorg } = profile;

  const companyRegisterData = useSelector((state) => state.companyRegisterData);
  const orgList = useSelector((state) => state.companyRegisterData?.orgList);
  const { orgListStatus, orgListErrorMessage } = companyRegisterData;

  const authData = useSelector((state) => state.auth);
  const { isFederatedUser } = authData;

  const dispatch = useDispatch();

  useEffect(() => {
    setValue({
      primaryOrganization: mcouserorg,
      additionalOrganization: mcomultiorg ? mcomultiorg?.split(',') : undefined
    });
  }, [mcouserorg, mcomultiorg, isDialogOpen]);

  useEffect(() => {
    setOpen(isDialogOpen);
  }, [isDialogOpen]);

  useEffect(() => {
    if (open) {
      dispatch(
        fetchOrganization(profile?.companyNodeId ? profile.companyNodeId : '')
      );
    }
  }, [open]);

  useEffect(() => {
    checkValidation();
  }, [value]);

  const dialogOpen = () => {
    setOpen(true);
    if (onChange) onChange(true);
  };

  const dialogClose = () => {
    setOpen(false);
    if (onChange) onChange(false);
  };

  const checkValidation = () => {
    let errors = { ...validation };
    if (orgList && orgList?.length && !value?.primaryOrganization?.trim()) {
      errors.primaryOrganization = 'Primary Organization is required';
    } else {
      delete errors.primaryOrganization;
    }
    setValidation(errors);
    return Object.keys(errors).length === 0;
  };

  const updateProfile = (profileUpdateData = {}) => {
    let apiUrl = '/api/v1/user/org/update';

    const finalProfileUpdateData = {
      isFederatedUser: isFederatedUser,
      primaryCompany: profileUpdateData.primaryOrganization,
      secondaryCompanies: profileUpdateData?.additionalOrganization?.join(',')
    };

    setIsLoading(true);
    return httpUtil
      .post(apiUrl, finalProfileUpdateData)
      .then((res) => {
        setIsLoading(false);
        dispatch(fetchProfile());
        notify(`Organization is updated successfully.`, 'success');
        setOpen(false);
        if (onChange) onChange(false);
        return res;
      })
      .catch((error) => {
        setIsLoading(false);
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while updating profile ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
      });
  };

  const primaryOrganizationChange = (newValue) => {
    const selectedOrg = newValue?.value ? newValue.value : undefined;
    const selectedSecondaryOrganization = value[
      'additionalOrganization'
    ]?.filter((item) => !(selectedOrg === item));
    setValue({
      ...value,
      ...{
        primaryOrganization: selectedOrg,
        additionalOrganization: selectedSecondaryOrganization
      }
    });
  };

  const additionalOrganizationChange = (newValue = []) => {
    const selectedOrg = newValue?.map((item) => item.value) || [];
    let selectedPrimaryOrganization = value['primaryOrganization'];

    if (selectedOrg?.includes(selectedPrimaryOrganization)) {
      selectedPrimaryOrganization = undefined;
    }

    setValue({
      ...value,
      ...{
        primaryOrganization: selectedPrimaryOrganization,
        additionalOrganization: selectedOrg
      }
    });
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitted(true);
    if (checkValidation()) {
      updateProfile(value);
    }
  };

  return (
    <>
      <form name="statusform" onSubmit={handleSubmit}>
        {buttonTitle && <a onClick={dialogOpen}>{buttonTitle}</a>}
        <Dialog
          open={open}
          size="medium"
          onDialogClose={dialogClose}
          closeOnClickOutside={false}
          title={`Update Organization`}
          body={
            <div>
              {isLoading || orgListStatus === 'PENDING' ? (
                <div className="center-holder-wrap">
                  <Loader />
                </div>
              ) : null}
              <div className="panel panel--bordered ">
                <div className="row half-padding-top half-padding-bottom">
                  <div className="col-md-12">
                    <div className="form-group">
                      <div className="form-group__text">
                        <label
                          htmlFor="select-primary-org"
                          className={`${
                            orgList && orgList?.length ? 'required' : null
                          }`}
                        >
                          Primary Organization
                        </label>
                        <div>
                          <Select
                            value={
                              value?.primaryOrganization
                                ? {
                                    value: value?.primaryOrganization,
                                    label: value?.primaryOrganization
                                  }
                                : ''
                            }
                            name="primaryOrganization"
                            isClearable
                            options={
                              orgList?.length
                                ? orgList?.map((item) => {
                                    return { value: item, label: item };
                                  })
                                : []
                            }
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={primaryOrganizationChange}
                          />
                        </div>

                        {isSubmitted && validation.primaryOrganization && (
                          <span className="text-danger text-size-12 qtr-margin-top">
                            {validation.primaryOrganization}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  <div className="col-md-12 base-margin-top">
                    <div className="form-group">
                      <div className="form-group__text">
                        <label htmlFor="select-additionalOrganization">
                          Additional Organization (Optional)
                        </label>
                        <Select
                          value={
                            value?.additionalOrganization?.map((item) => {
                              return { value: item, label: item };
                            }) || []
                          }
                          isMulti
                          isClearable
                          name="additionalOrganization"
                          options={
                            orgList?.length
                              ? orgList?.map((item) => {
                                  return { value: item, label: item };
                                })
                              : []
                          }
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={additionalOrganizationChange}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          }
          footer={
            <div>
              <button
                type="button"
                onClick={() => dialogClose()}
                className="btn btn--ghost"
                disabled={isLoading}
              >
                Cancel
              </button>
              <button
                type="submit"
                className="btn"
                disabled={isLoading || Object.keys(validation).length}
              >
                Update
              </button>
            </div>
          }
        />
      </form>
    </>
  );
};

export default OrgEditDialog;
