import React, { useEffect, useState } from 'react';
import InlineEdit from '../../../molecules/InlineEdit';
import { useSelector } from 'react-redux';
import Select, { SelectOption } from '../../../molecules/Select';
import { updateCustomers } from '../../../../services';
import notify from '../../../../helpers/notification';
import {
  arraysContainSameElements,
  getAddRemovedPayload
} from '../../../../helpers/common';
export interface CustomersEditProps {
  isEditable?: boolean;
  title?: React.ReactNode;
  open?: boolean;
  onOpenChange?: Function;
  companyType?: string;
  value?: any;
  userType?: string[];
  isLoading?: boolean;
  companyId?: string;
  companyNodeId?: string;
  updateCustomersFormValue?: Function;
  required?: boolean;
}

function CustomersEdit({
  isEditable = true,
  title,
  open,
  onOpenChange,
  value,
  isLoading = false,
  userType,
  companyId,
  companyNodeId,
  updateCustomersFormValue,
  required
}: CustomersEditProps) {
  const [edit, setEdit] = useState<boolean>(false);
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
  const [status, setStatus] = useState<string | undefined>();
  const [editValue, setEditValue] = useState<any>();
  const [error, setError] = useState<any | undefined>();

  const companyOnboardData = useSelector((state: any) => state.onboardCompany);
  const { customers = [] } = companyOnboardData;
  useEffect(() => {
    setEdit(!!open);
  }, [open]);

  useEffect(() => {
    setEditValue(value);
  }, [value]);

  useEffect(() => {
    // validate();
  }, [editValue]);

  const onEditChange = () => {
    setEdit(true);
    if (onOpenChange) {
      onOpenChange(true);
    }
  };

  const onEditCancel = () => {
    setEditValue(value);
    setEdit(false);
    if (onOpenChange) {
      onOpenChange(false);
    }
    setIsSubmitted(false);
  };

  const onClickSave = async () => {
    setIsSubmitted(true);
    setError(undefined);
    const error: any = {};
    if (required && !editValue.length) {
      error.message = 'Customers is required';
    }

    if (Object.keys(error).length > 0) {
      return setError({ ...error });
    }

    const addRemovedPayload = getAddRemovedPayload(
      value?.map((item: SelectOption) => item?.value) || [],
      editValue?.map((item: SelectOption) => item?.value) || []
    );

    const payload = {
      companyId,
      companyNodeId: companyNodeId,
      userType: userType
        ? userType?.map((item: string) => item?.toUpperCase())
        : [],
      customers: editValue?.map((res: SelectOption) => res?.value) || [],
      addCustomersList: addRemovedPayload.addedList,
      removeCustomersList: addRemovedPayload.removedList
    };

    setStatus('PENDING');

    try {
      await updateCustomers(payload);
      setStatus('SUCCESS');
      setEdit(false);
      if (updateCustomersFormValue)
        updateCustomersFormValue({ customers: editValue });

      notify('Customers is updated successfully.', 'success');
    } catch (e) {
      setStatus('ERROR');
      const errorMessage = `An error occurred while updating Customers ${
        error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
      }`;
      notify(errorMessage, 'error');
    }
  };

  const onCustomersChange = (newValue = []) => {
    setEditValue(newValue);
  };

  return (
    <div>
      {isSubmitted && edit && error?.message ? (
        <div className="alert alert--warning">
          <div className="alert__icon icon-warning-outline"></div>
          <div className="alert__message">{error?.message}</div>
        </div>
      ) : null}

      <InlineEdit
        isDisableSave={arraysContainSameElements(
          editValue?.map(({ value }: any) => value),
          value?.map(({ value }: any) => value)
        )}
        isEditable={isEditable}
        title={title}
        readContent={
          <div className="edit-form-value-holder">
            {' '}
            {editValue?.map((item: any) => item.value).join(', ')}
          </div>
        }
        isEditing={edit}
        onChangeEdit={onEditChange}
        onCancelEdit={onEditCancel}
        isLoading={status === 'PENDING' || isLoading}
        editContent={
          <div className="edit-form-value-holder">
            <div className="flex flex-between flex-middle">
              <div style={{ flex: 1 }}>
                <Select
                  value={editValue}
                  isMulti
                  name="Customers"
                  options={
                    customers?.length
                      ? customers.map((item: any) => {
                          return {
                            value: item,
                            label: item
                          };
                        })
                      : []
                  }
                  className="basic-multi-select"
                  classNamePrefix="select"
                  onChange={onCustomersChange}
                />
              </div>
            </div>
          </div>
        }
        onSave={onClickSave}
      />
    </div>
  );
}

export default CustomersEdit;
