import React, { useState, useEffect, useRef, useMemo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { AgGridReact } from 'ag-grid-react';
import { useLocation } from 'react-router-dom';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import notify from '../../../helpers/notification';
import httpUtil from '../../../helpers/interceptor';
import Loader from '../../atoms/Loader';
import { useNavigate } from 'react-router-dom';
import CompanyOnboardView from '../../molecules/CompanyOnboardView/CompanyOnboardView';
import { hasAccessForResourceRole, hasAccess } from '../../../helpers';
import { fetchCompanies, fetchCompaniesExcelExport } from '../../../actions';
import Filters from '../../molecules/Filters/Filters';
import ExcelExport from '../../atoms/ExcelExport';
import PaginationPanel from '../../atoms/PaginationPanel';
import './index.css';
import { noteToolTip } from '../../../helpers/common';

const AddressTooltip = (props) => {
  const data = useMemo(
    () => props.api.getDisplayedRowAtIndex(props.rowIndex).data,
    []
  );

  return (
    <div
      className="custom-tooltip"
      style={{ backgroundColor: props.color || 'white' }}
    >
      <p>
        <span>Address Line 1: {data?.address1 || '-'}</span>
      </p>
      <p>
        <span>Address Line 2: {data?.address2 || '-'}</span>
      </p>
      <p>
        <span>City: {data?.city || '-'}</span>
      </p>
      <p>
        <span>State: {data?.state || '-'}</span>
      </p>
      <p>
        <span>Country: {data?.country || '-'}</span>
      </p>
      <p>
        <span>Postal Code: {data?.postalCode || '-'}</span>
      </p>
    </div>
  );
};

const SearchCompaniesTable = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { fromSource } = location.state || {};
  const authData = useSelector((state) => state.auth);
  const companyData = useSelector((state) => state?.company?.companiesData);
  const getCompanyListStatus = useSelector(
    (state) => state?.company?.getCompanyListStatus
  );
  const { actualUserType, user, userActiveRolePermissions } = authData;
  const { companies, filter } = companyData || {};
  const gridRef = useRef(null);
  const navigate = useNavigate();
  const [gridApi, setGridApi] = useState(null);

  const [company, setCompany] = useState();
  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [viewLoader, setViewLoader] = useState(false);

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [getCompanyErrorMessage, setGetCompanyErrorMessage] =
    useState(undefined);
  const [validation, setValidation] = useState({});
  const [filterValue, setFilterValue] = useState({
    includeInactive: false
  });
  const [updatePaginationPanel, setUpdatePaginationPanel] = useState();
  const [loading, setLoading] = useState(false);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const handleChange = (event) => {
    // setIsSubmitted(false);
    //  setRows([]);
    if (event.target.name === 'includeInactive') {
      setFilterValue((preVal) => {
        return {
          ...(preVal || {}),
          ...{ [event.target.name]: event.target.checked }
        };
      });
    } else {
      setFilterValue((preVal) => {
        return {
          ...(preVal || {}),
          ...{ [event.target.name]: event.target.value }
        };
      });
    }
  };

  useEffect(() => {
    checkValidation();
  }, [filterValue]);

  useEffect(() => {
    const filters = [
      {
        name: 'companyName',
        label: 'Company Name',
        type: 'text',
        show: true,
        value: ''
      },
      {
        name: 'vendorId',
        label: 'Vendor Id',
        tooltip: noteToolTip('vendorId', 'Please enter complete Vendor ID'),
        type: 'text',
        show: false,
        value: ''
      },
      {
        name: 'supplierSiteId',
        label: 'Site Id',
        type: 'text',
        show: false,
        value: ''
      },
      {
        name: 'companyType',
        label: 'Company Type',
        type: 'text',
        show: false,
        value: ''
      },
      {
        name: 'org',
        label: 'Company Org',
        type: 'text',
        show: false,
        value: ''
      },
      {
        name: 'dunsNumber',
        label: 'DUNS Number',
        type: 'text',
        show: false,
        value: ''
      },
      {
        name: 'domainName',
        label: 'Email Domains',
        type: 'text',
        show: false,
        value: ''
      },
      {
        name: 'companyGroupName',
        label: 'Group Name',
        type: 'text',
        show: false,
        value: ''
      },
      {
        name: 'status',
        label: 'SCREG Status',
        type: 'select',
        options: [
          { value: 'active', label: 'Active' },
          { value: 'inactive', label: 'Inactive' }
        ],
        show: false,
        value: ''
      }
    ].filter((item) => {
      if (item.name === 'status' && !hasAccess('company', 'statusFilter')) {
        return false;
      }
      return true;
    });

    setFilterValue((preVal) => {
      return {
        ...(preVal || {}),
        ...{
          filters: filters
        }
      };
    });
  }, [filterValue?.searchUserType]);

  const checkValidation = () => {
    let errors = [];
    if (filterValue?.filters?.filter((item) => item?.show)?.length > 3) {
      errors.push({
        name: 'filterCount',
        message: 'Not allowed to add more than 3 filters'
      });
    }

    filterValue?.filters?.forEach((item) => {
      let error = {};
      if (item?.show) {
        let fieldValue = '';
        if (item?.type === 'select') {
          fieldValue = item?.value?.value?.trim();
        } else {
          fieldValue = item?.value?.trim();
        }
        if (fieldValue === '' || typeof fieldValue === 'undefined') {
          error.name = item?.name;
          error.message = `${item?.label} is required`;
        } else if (item?.name === 'vendorId') {
          if (isNaN(fieldValue)) {
            error.name = item?.name;
            error.message = `${item?.label} should be numeric`;
          }
        }
      }
      if (Object.keys(error).length) {
        errors.push(error);
      }
    });

    setValidation((preVal) => {
      return {
        ...(preVal || {}),
        ...{ filters: errors }
      };
    });

    return errors.length === 0;
  };

  const onChangeFilter = (filtersValue) => {
    const filtersValueTemp = filtersValue || [];
    let statusFilterHasValue = !!filtersValueTemp?.find((filterItem) => {
      return filterItem.name === 'status' && filterItem.value;
    });
    let statusFilterShow = !!filtersValueTemp?.find((filterItem) => {
      return filterItem.name === 'status' && filterItem.show;
    });

    let showFilterCount = filtersValueTemp?.filter((filterItem) => {
      return filterItem.show;
    });
    setFilterValue((preVal) => {
      return {
        ...({
          ...preVal,
          ...{
            includeInactive: statusFilterShow ? false : preVal?.includeInactive
          }
        } || {}),
        filters: filtersValue
      };
    });
  };

  useEffect(() => {
    let companiesListData = companies || [];
    companiesListData = companiesListData.map((item) => {
      let itemStatus = '';
      if (item?.activeFlag?.toLowerCase() === 'y') {
        itemStatus = 'Active';
      } else if (item?.activeFlag?.toLowerCase() === 'n') {
        itemStatus = 'Inactive';
      }
      item.status = itemStatus;
      item.fullAddress = `${item?.address1}${
        item?.address2 ? ', ' + item?.address2 + '' : ''
      }${item?.city ? ', ' + item?.city + '' : ''}${
        item?.state ? ', ' + item?.state + '' : ''
      }${item?.country ? ', ' + item?.country + '' : ''}${
        item?.postalCode ? ', ' + item?.postalCode + '' : ''
      }`;
      return item;
    });

    setRows(companiesListData);
  }, [companies]);

  useEffect(() => {
    if (fromSource !== 'companyDetail') {
      dispatch({ type: 'COMPANY_LIST_RESET' });
    }
  }, [fromSource]);

  useEffect(() => {
    if (getCompanyListStatus === 'SUCCESS') {
      setIsSubmitted(true);
    }
    if (typeof getCompanyListStatus === 'undefined') {
      setIsSubmitted(false);
    }
  }, [getCompanyListStatus, filter]);

  useEffect(() => {
    if (filter && fromSource === 'companyDetail') {
      setFilterValue((preVal) => {
        return { ...(preVal || {}), ...filter, filters: filter?.filters || [] };
      });
    }
  }, [filter, fromSource]);

  useEffect(() => {
    if (gridApi) {
      if (viewLoader) gridApi.showLoadingOverlay();
      else gridApi.hideOverlay();
    }
  }, [viewLoader]);

  const onClickViewDialogOpen = async (companyRow, isModify = false) => {
    const { _id: companyId } = companyRow;
    try {
      if (isModify) {
        return navigate(`/company-modify/${companyId}`);
      }

      const res = await httpUtil.post(
        `/api/am/dashboard/v1/company/existingCompanySearch`,
        {
          companyId
        }
      );
      setDialogOpen(true);
      setViewLoader(false);
      setCompany(res);
    } catch (error) {
      setGetCompanyErrorMessage(error);
      // setStatus('ERROR');
      setViewLoader(false);
      const errorMessage = `An error occurred while fetching company ${
        error?.response?.data?.error ? ': ' + error?.response?.data?.error : ''
      }`;
      notify(errorMessage, 'error');
    }
  };

  useEffect(() => {
    const filterParams = {
      suppressAndOrCondition: true,
      filterOptions: ['contains', 'notContains', 'equals', 'notEqual']
    };

    const columnsList = [
      {
        headerName: 'Company Name',
        field: 'companyName',
        filter: true,
        filterParams,
        width: 200,
        pinned: 'left',
        tooltipField: 'companyName'
      },
      {
        headerName: 'Vendor Id',
        field: 'vendorId',
        filterParams,
        filter: true,
        width: 100,
        tooltipField: 'vendorId'
      },
      {
        headerName: 'Site Id',
        field: 'supplierSiteId',
        filter: true,
        tooltipField: 'supplierSiteId',
        width: 100,
        filterParams
      },
      {
        headerName: 'Site Address',
        field: 'fullAddress',
        filter: true,
        tooltipField: 'fullAddress',
        flex: 1,
        minWidth: 250,
        filterParams,
        tooltipComponent: AddressTooltip
      },
      {
        headerName: 'Company Type',
        field: 'companyTypeName',
        filterParams,
        width: 160,
        filter: true,
        tooltipField: 'companyTypeName'
      },
      {
        headerName: 'Company Orgs',
        field: 'orgs',
        filterParams,
        width: 150,
        filter: true,
        tooltipField: 'orgs',
        cellRenderer: (params) => {
          const orgs = params.data?.isOrgShow ? params.value : [];
          return orgs && orgs?.length ? orgs.join(', ').toString() : '';
        }
      },
      {
        headerName: 'DUNS Number',
        field: 'dunsNumber',
        filterParams,
        width: 120,
        filter: true,
        tooltipField: 'dunsNumber'
      },
      {
        headerName: 'Email Domains',
        field: 'domainName',
        filterParams,
        width: 180,
        filter: true,
        tooltipField: 'domainName',
        cellRenderer: (params) => {
          const domainName = params.value;
          return domainName && domainName?.length
            ? domainName.join(', ').toString()
            : '';
        }
      },
      {
        headerName: 'Group Name',
        field: 'companyGroupName',
        filterParams,
        width: 140,
        filter: true,
        tooltipField: 'companyGroupName'
      },
      {
        headerName: 'SCREG Status',
        field: 'status',
        filterParams,
        filter: true,
        width: 120,
        pinned: 'right',
        cellRenderer: (params) => {
          return (
            <>
              {' '}
              {params.data?.activeFlag?.toLowerCase() == 'y' ? (
                <span className="label label--small  label--success  label--bordered">
                  Active
                </span>
              ) : (
                <span className="label label--small label--warning label--bordered">
                  Inactive
                </span>
              )}
              {params.data?.revalidationStatus &&
              params.data?.revalidationStatus?.toLowerCase() ===
                'inprogress' ? (
                <span className="qtr-margin-left">
                  Re-Validation:{' '}
                  <span className="">
                    {params.data?.revalidationStatus &&
                    params.data?.revalidationStatus?.toLowerCase() ===
                      'inprogress'
                      ? 'In Process'
                      : null}
                  </span>
                </span>
              ) : null}
              {params.data?.onboardingStatus?.toLowerCase() !== 'completed' &&
              params.data?.activeFlag?.toLowerCase() !== 'y' ? (
                <>
                  {params.data?.onboardingStatus
                    ?.toLowerCase()
                    .includes('offboarding') ? (
                    <span className="qtr-margin-left">
                      Off-boarding:{' '}
                      <span className="">
                        {params.data?.onboardingStatus &&
                        params.data?.onboardingStatus?.toLowerCase() ===
                          'offboarding - inprogress'
                          ? 'In Process'
                          : null}
                        {params.data?.onboardingStatus &&
                        params.data?.onboardingStatus?.toLowerCase() ===
                          'offboarding - completed'
                          ? 'Completed'
                          : null}
                      </span>
                    </span>
                  ) : (
                    <span className="qtr-margin-left">
                      On-boarding:{' '}
                      <span className="">
                        {params.data?.onboardingStatus?.toLowerCase() ===
                        'inprogress'
                          ? 'In Process'
                          : null}
                        {params.data?.onboardingStatus &&
                        params.data?.onboardingStatus?.toLowerCase() !==
                          'inprogress'
                          ? params.data?.onboardingStatus
                              .charAt(0)
                              .toUpperCase() +
                            params.data?.onboardingStatus.slice(1).toLowerCase()
                          : null}
                      </span>
                    </span>
                  )}
                </>
              ) : null}
            </>
          );
        }
      },
      {
        headerName: 'Action',
        field: 'action',
        pinned: 'right',
        width: 130,
        suppressMenu: true,
        cellRenderer: (params) => {
          return (
            <>
              <a
                onClick={() => {
                  setViewLoader(true);
                  onClickViewDialogOpen(params.data);
                }}
              >
                View
              </a>

              <>
                {hasAccessForResourceRole(
                  [
                    {
                      roleName: params.data?.companyTypeGroupName || '',
                      exist: !!userActiveRolePermissions?.find(
                        (roleItem) =>
                          roleItem?.name === params.data?.companyTypeGroupName
                      )
                    },
                    {
                      roleName: 'pam',
                      exist: !!params.data?.pamList?.includes(user?.ccoid)
                    },
                    {
                      roleName: 'sponsor',
                      exist: !!params.data?.sponsor?.includes(user?.ccoid)
                    },
                    {
                      roleName: 'pa',
                      exist: !!params.data?.paList?.includes(user?.email)
                    }
                  ],
                  'company',
                  'update'
                ) &&
                params.data?.onboardingStatus?.toLowerCase() !==
                  'offboarding - inprogress' ? (
                  <>
                    {' '}
                    /{' '}
                    <a
                      onClick={() => {
                        setViewLoader(true);
                        onClickViewDialogOpen(params.data, true);
                      }}
                    >
                      Modify
                    </a>
                  </>
                ) : null}{' '}
                {/* {params.data?.activeFlag?.toLowerCase() === 'y' &&
                hasAccess('company', 'update') ? (
                  <>
                    /{' '}
                    <a
                      onClick={() => {
                        onClickDetailsDialogOpen(params.data);
                      }}
                    >
                      Deactivate
                    </a>
                  </>
                ) : null} */}
              </>
            </>
          );
        }
      }
    ].map((item) => {
      let itemTemp = item;
      itemTemp.headerClass = 'text-weight-900';
      itemTemp.resizable = true;
      // itemTemp.flex = 1;
      itemTemp.columnsMenuParams = {
        suppressColumnSelectAll: false,
        suppressColumnExpandAll: false,
        suppressColumnFilter: false
      };

      itemTemp.menuTabs = ['filterMenuTab'];

      itemTemp.sortable = false;
      return itemTemp;
    });
    setColumns(columnsList);
  }, [gridApi]);

  const onClickDetailsDialogOpen = (requestRow) => {
    setDialogOpen(true);
  };

  const companyFilter = useMemo(() => {
    const newCompanyFilter = {
      includeInactive: filterValue?.includeInactive,
      userType: actualUserType
        ? actualUserType.map((item) => item?.toUpperCase())
        : []
    };

    filterValue?.filters?.forEach((item) => {
      if (typeof item?.value !== 'undefined' && item?.show) {
        if (item?.type === 'select') {
          newCompanyFilter[item.name] = item?.value?.value;
        } else if (item?.value !== '') {
          newCompanyFilter[item.name] = item?.value;
        }
      }
    });

    return newCompanyFilter;
  }, [filterValue, actualUserType]);

  const handleSubmit = (event) => {
    event.preventDefault();
    setIsSubmitted(true);

    if (!checkValidation()) {
      return;
    }

    // const companyFilter = {
    //   includeInactive: filterValue?.includeInactive,
    //   userType: actualUserType
    //     ? actualUserType?.map((item) => item?.toUpperCase())
    //     : []
    // };

    // filterValue?.filters?.forEach((item) => {
    //   if (typeof item?.value !== 'undefined' && item?.show) {
    //     if (item?.type === 'select') {
    //       companyFilter[item.name] = item?.value?.value;
    //     } else if (item?.value !== '') {
    //       companyFilter[item.name] = item?.value;
    //     }
    //   }
    // });

    dispatch(
      fetchCompanies(
        { ...companyFilter, excelExport: false },
        filterValue?.filters
      )
    );
  };

  useEffect(() => {
    if (gridApi) {
      if (getCompanyListStatus === 'PENDING') {
        gridApi.showLoadingOverlay();
      } else {
        if (rows && rows.length === 0) {
          gridApi.showNoRowsOverlay();
        } else {
          gridApi.hideOverlay();
        }
      }
    }
  }, [rows, getCompanyListStatus, gridApi]);

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#f2f2f2' };
    }
  };

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      sortable: false,
      minWidth: 100,
      filter: false,
      resizable: true
    };
  }, []);

  async function exportToExcel() {
    try {
      setLoading(true);
      const res = await fetchCompaniesExcelExport({
        ...companyFilter,
        excelExport: true
      });
      setLoading(false);
      if (res) {
        return res?.map(
          ({
            companyName,
            vendorId,
            supplierSiteId,
            address1,
            address2,
            city,
            state,
            country,
            zip,
            companyTypeName,
            orgs,
            activeFlag,
            onboardingStatus,
            dunsNumber,
            domainName,
            companyGroupName,
            isOrgShow
          }) => {
            const siteAddress = `${address1 ? address1 : ''}${
              address2 ? ', ' + address2 : ''
            }${city ? ', ' + city : ''}${state ? ', ' + state : ''}${
              country ? ', ' + country : ''
            }${zip ? ', ' + zip : ''}`;

            let scRegStatus = '';

            if (activeFlag?.toLowerCase() == 'y') {
              scRegStatus = 'Active';
            } else {
              scRegStatus = 'Inactive';
            }

            if (
              onboardingStatus?.toLowerCase() !== 'completed' &&
              activeFlag?.toLowerCase() !== 'y'
            ) {
              scRegStatus += ' On-boarding: ';

              if (onboardingStatus?.toLowerCase() === 'inprogress') {
                scRegStatus += 'In Process';
              }

              if (
                onboardingStatus &&
                onboardingStatus?.toLowerCase() !== 'inprogress'
              ) {
                scRegStatus +=
                  onboardingStatus.charAt(0).toUpperCase() +
                  onboardingStatus.slice(1).toLowerCase();
              }
            }

            return {
              'Company Name': companyName || '',
              'Vendor Id': vendorId || '',
              'Site Id': supplierSiteId || '',
              'Site Address': siteAddress || '',
              'Company Type': companyTypeName || '',
              'Company Orgs': isOrgShow ? orgs?.join(', ') : '' || '',
              'DUNS Number': dunsNumber || '',
              'Email Domains': domainName?.join(', ') || '',
              'Group Name': companyGroupName?.join(', ') || '',
              'SCREG Status': scRegStatus || ''
            };
          }
        );
      }
      return [{ error: 'No Data' }];
    } catch (e) {
      setLoading(false);
      return [{ error: e?.message }];
    }
  }

  const onPaginationChanged = React.useCallback(() => {
    // Workaround for bug in events order
    if (gridRef?.current?.api) {
      setUpdatePaginationPanel(Math.random());
    }
  }, []);

  return (
    <div className="">
      <div className="card qtr-margin-top qtr-margin-bottom">
        <div className="card-header">
          <h5 className="text-primary text-capitalize">
            Supply Chain - Maintain Company Profiles
          </h5>
        </div>

        <div className="card-body">
          <form method="POST" onSubmit={handleSubmit}>
            <div className="row">
              <div className="col-md-12">
                <div className="filter-bar-row filter-tool">
                  <div className="filter-bar-item filter-bar-left filter-tool-item">
                    <div className="flex">
                      <span className="base-margin-right half-margin-top">
                        Search Company By
                      </span>
                    </div>

                    <div className="">
                      <div
                        className="form-group form-group--inline form-group--compressed half-margin-top"
                        style={
                          filterValue?.filters?.find(
                            (filterItem) => filterItem?.name === 'status'
                          )?.show
                            ? { backgroundColor: '#eee' }
                            : {}
                        }
                      >
                        <label className="checkbox">
                          <input
                            type="checkbox"
                            name="includeInactive"
                            checked={filterValue?.includeInactive === true}
                            onChange={handleChange}
                            disabled={
                              filterValue?.filters?.find(
                                (filterItem) => filterItem?.name === 'status'
                              )?.value
                            }
                          />
                          <span className="checkbox__input"></span>
                          <span className="checkbox__label">
                            Include inactive companies in search
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div className="filter-bar-item filter-bar-center filter-tool-item">
                    <div className="row">
                      <div className="col-auto" style={{ maxWidth: '100%' }}>
                        <Filters
                          filters={filterValue?.filters || []}
                          onChange={onChangeFilter}
                          validation={
                            isSubmitted && validation?.filters
                              ? validation?.filters
                              : []
                          }
                          searchButton={
                            <>
                              <button
                                type="submit"
                                className="btn"
                                disabled={status === 'PENDING'}
                              >
                                Search
                              </button>
                              <div className="">
                                <span className="text-warning text-size-12">
                                  Note : Please select up to 3 filters{' '}
                                </span>
                              </div>
                            </>
                          }
                        />
                      </div>
                    </div>
                  </div>
                  <div className="filter-bar-item filter-bar-right filter-tool-item">
                    <div className="filter-bar-right-content">
                      {hasAccess('company', 'excelExport') && (
                        <div className="pull-right">
                          <ExcelExport
                            disabled={loading}
                            loading={loading}
                            fileName="companies"
                            getData={() => exportToExcel()}
                            hidden={rows.length == 0}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                </div>

                {/* <div className="form-group__text select base-margin-left">
                  <select
                    name="condition"
                    value={value.condition || 'contains'}
                    onChange={handleChange}
                  >
                    <option value="contains">contains</option>
                    <option value="startWith">starts with</option>
                    <option value="equals">equals</option>
                    <option value="endsWith">ends with</option>
                  </select>
                </div> */}

                {validation?.filters &&
                validation?.filters?.filter(
                  (filterItem) => filterItem?.name === 'filterCount'
                )?.length ? (
                  <div
                    className="alert alert--warning"
                    style={{ marginBottom: '8px' }}
                  >
                    <div className="alert__icon icon-warning-outline"></div>
                    <div className="alert__message">
                      {
                        validation?.filters?.find(
                          (filterItem) => filterItem?.name === 'filterCount'
                        )?.message
                      }
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </form>
        </div>
      </div>

      {isSubmitted && (
        <div
          className="ag-theme-alpine"
          style={{
            height: 470,
            width: '100%',
            display:
              isSubmitted && getCompanyListStatus === 'SUCCESS' && rows.length
                ? 'block'
                : 'none'
          }}
        >
          <AgGridReact
            ref={gridRef}
            rowData={rows}
            columnDefs={columns}
            defaultColDef={defaultColDef}
            gridOptions={{ suppressContextMenu: true }}
            pagination
            paginationPageSize={10}
            onGridReady={onGridReady}
            overlayNoRowsTemplate={`<span style="padding: 10px;">No companies found</span>`}
            loadingOverlayComponent={Loader}
            enableCellTextSelection={true}
            suppressCellFocus={true}
            getRowStyle={getRowStyle}
            headerHeight={38}
            rowHeight={38}
            tooltipShowDelay={0}
            suppressPaginationPanel={true}
            containerStyle={{ height: '95%' }}
            onPaginationChanged={onPaginationChanged}
          ></AgGridReact>
          <PaginationPanel
            gridRef={gridRef}
            updatePaginationPanel={updatePaginationPanel}
          />
        </div>
      )}
      {!isSubmitted ? (
        <div>
          <div className="text-center">
            <span className="icon-file-text text-size-128 text-gray-300"></span>
            <div className="text-gray-600">
              Try Adjusting your search or filter to find what your looking for.
            </div>
          </div>
        </div>
      ) : null}

      {isSubmitted &&
      getCompanyListStatus === 'SUCCESS' &&
      rows.length === 0 ? (
        <div>
          <div className="text-center">
            <span className="icon-file text-size-128 text-gray-300"></span>
            <div className="text-secondary text-size-20 base-margin-bottom">
              No Results
            </div>
            <div className="text-gray-600">
              Try Adjusting your search or filter to find what your looking for.
            </div>
          </div>
        </div>
      ) : null}

      {getCompanyListStatus === 'PENDING' ? (
        <div className="dbl-padding " style={{ marginTop: '10%' }}>
          <div className="text-center">
            <Loader />
          </div>
        </div>
      ) : null}

      {!viewLoader && (
        <CompanyOnboardView
          companyDetail={company || {}}
          open={dialogOpen}
          onChange={(dialogStatus) => {
            setDialogOpen(dialogStatus);
          }}
        />
      )}
    </div>
  );
};
export default SearchCompaniesTable;
