import React from 'react';
import XLSX from 'xlsx';
import { saveAs } from 'file-saver';

type excelExportType = {
  fileName: string;
  hidden: boolean;
  disabled?: boolean;
  loading?: boolean;
  getData: Function;
};

const ExcelExport = ({
  fileName,
  hidden,
  getData,
  disabled = false,
  loading = false
}: excelExportType) => {
  const exportToExcel = async () => {
    const data = await getData();
    const worksheet = XLSX.utils.json_to_sheet(data);
    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, 'Sheet1');
    const excelBuffer = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array'
    });
    const blob = new Blob([excelBuffer], {
      type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
    });
    saveAs(blob, `${fileName}.xlsx`);
  };

  return (
    <>
      {!hidden && (
        <button
          disabled={disabled}
          type="button"
          onClick={exportToExcel}
          className="btn btn--ghost"
        >
          {' '}
          <span className="icon-file-excel-o"></span> Excel Export{' '}
          {loading && (
            <span
              className={`icon-refresh spin`}
              style={{ lineHeight: '24px' }}
            ></span>
          )}
        </button>
      )}
    </>
  );
};

export default ExcelExport;
