import React, { useState, useEffect } from 'react';
import Dialog from '../../../atoms/Dialog/Dialog';
import { useDispatch, useSelector } from 'react-redux';
import { components, OptionProps } from 'react-select';

import {
  fetchAllCompanyTypes,
  fetchAllOrganization,
  fetchCompanyTypes,
  fetchOperationalAdmin,
  fetchCountries,
  fetchCustomers
} from '../../../../actions';
import Select, { SelectOption } from '../../../molecules/Select';
import { CompanyType } from '../../../../interfaces';
import FullScreenLoader from '../../../molecules/FullScreenLoader/FullScreenLoader';
import Loader from '../../../atoms/Loader/Loader';
import _ from 'lodash';
import notify from '../../../../helpers/notification';
import OrgSelect from '../../../molecules/OrgSelect/OrgSelect';
import UsersListValue from '../../../atoms/UsersListValue';
import {
  fetchExistingCompanySearch,
  updateCompanyType
} from '../../../../services';
import {
  arraysContainSameElements,
  getAddRemovedPayload
} from '../../../../helpers/common';

type CompanyDetailTypes = {
  companyType: string;
  organizations: [];
  countries: [];
  customers: [];
};

export interface CompanyTypeDialogProps {
  companyDetail: CompanyDetailTypes;
  companyId: string | undefined;
  open: boolean;
  onChange: Function;
  updateFormValue: Function;
}

const validationData = {
  companyType: '',
  countries: '',
  organizations: '',
  customers: '',
  primaryOperationalAdmin: ''
};

const UserSelectOption = (props: OptionProps<SelectOption>) => {
  const { data } = props;
  return (
    <div>
      <components.Option {...props}>
        {data?.firstName + ' ' + data?.lastName} ({data?.userId}){' '}
      </components.Option>
    </div>
  );
};

const CompanyTypeDialog = ({
  companyDetail,
  open: isDialogOpen = false,
  companyId,
  onChange = (isVisble: boolean) => {},
  updateFormValue
}: CompanyTypeDialogProps) => {
  const dispatch = useDispatch();

  const [open, setOpen] = useState(false);
  const [validation, setValidation] = useState<any>(validationData);
  const [loading, setLoading] = useState(false);
  const [formValue, setFormValue] = React.useState<any>({
    companyType: ''
  });
  const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

  const companyOnboardData = useSelector((state: any) => state.onboardCompany);

  const {
    countries = [],
    customers = [],
    organizations: organizationsOptions = [],
    allCompanyTypes = [],
    getOrganizationStatus,
    getCustomersStatus,
    getCountriesStatus,
    getAllCompanyTypesStatus
  } = companyOnboardData;
  const operationalAdminList = useSelector(
    (state: any) => state.onboardCompany?.operationalAdmins
  );
  const getOperationalAdminStatus = useSelector(
    (state: any) => state.onboardCompany?.getOperationalAdminStatus
  );

  useEffect(() => {
    dispatch(fetchAllCompanyTypes());
    dispatch(fetchAllOrganization());
  }, []);

  useEffect(() => {
    if (formValue?.isCountryMandatory && getCountriesStatus !== 'SUCCESS') {
      dispatch(fetchCountries());
    }

    if (formValue?.isCustomerMandatory && getCustomersStatus !== 'SUCCESS') {
      dispatch(fetchCustomers());
    }

    if (formValue?.companyType) {
      dispatch(fetchOperationalAdmin(formValue?.companyType));
    }
  }, [formValue?.companyType]);

  const onSelectorChange = (
    newValue:
      | SelectOption
      | SelectOption[]
      | string[]
      | string
      | undefined
      | number,
    name: string
  ) => {
    setFormValue({
      ...formValue,
      ...{
        [name]: newValue
      }
    });
  };

  const onCountriesChange = (newValue = []) => {
    setFormValue((prev: any) => ({
      ...prev,
      ...{
        countries: newValue
      }
    }));
  };

  useEffect(() => {
    setOpen(isDialogOpen);
  }, [isDialogOpen]);

  const dialogClose = () => {
    if (!loading) {
      setOpen(false);
      if (onChange) onChange(false);
    }
  };

  useEffect(() => {
    setFormValue((prev: any) => ({
      ...prev,
      ...companyDetail
    }));
  }, []);

  const onCompanyTypeChange = (newValue: SelectOption | undefined) => {
    const companyTypeValue = newValue?.label;
    const companyTypeId = newValue?.value;

    setFormValue({
      ...formValue,
      ...{
        ['companyType']: companyTypeValue,
        companyTypeId: companyTypeId,
        isOrgMandatory: newValue?.data?.isOrgMandatory,
        isCountryMandatory: newValue?.data?.isCountryMandatory,
        isCustomerMandatory: newValue?.data?.isCustomerMandatory,
        isOrgShow: newValue?.data?.isOrgShow,
        isCountryShow: newValue?.data?.isCountryShow,
        isCustomerShow: newValue?.data?.isCustomerShow,
        organizations: undefined,
        countries: undefined,
        customers: undefined,
        primaryOperationalAdmin: undefined,
        secondaryOperationalAdmin: undefined
      }
    });
    setValidation(validationData);
  };

  const organizationsChange = (newValue = []) => {
    const selectedOrganizations: string[] =
      newValue?.map((item: SelectOption) => item.value) || [];

    setFormValue((prev: any) => ({
      ...prev,
      ...{
        organizations: selectedOrganizations
      }
    }));
  };

  const primaryOperationalAdminChange = (
    newValue: SelectOption | undefined
  ) => {
    const selectedAdmin = newValue ? [newValue] : [];
    let selectedSecondaryAdmin = formValue?.secondaryOperationalAdmin || [];
    selectedSecondaryAdmin = operationalAdminList?.filter(
      (item: SelectOption) =>
        !selectedAdmin.find(
          (selectedItem: SelectOption) => selectedItem.value === item.value
        )
    );
    setFormValue({
      ...formValue,
      ...{
        primaryOperationalAdmin: selectedAdmin,
        secondaryOperationalAdmin: selectedSecondaryAdmin
      }
    });
  };

  useEffect(() => {
    checkValidation();
  }, [formValue]);

  const userExistsInAllRole = (val: string) => {
    return (
      formValue?.pamList?.includes(val) && formValue?.sponsorList?.includes(val)
    );
  };

  const checkValidation = () => {
    let errors: any = {};

    if (!formValue?.companyType) {
      errors['companyType'] = 'Company Type is required';
    }

    if (formValue?.isCountryShow && formValue?.isCountryMandatory) {
      if (!formValue?.countries?.length) {
        errors.countries = 'Countries is required';
      }
    }

    if (formValue?.isCustomerShow && formValue?.isCustomerMandatory) {
      if (!formValue?.customers?.length) {
        errors.customers = 'Customers is required';
      }
    }

    if (formValue?.isOrgShow && formValue?.isOrgMandatory) {
      if (!formValue?.organizations || !formValue?.organizations?.length) {
        errors.organizations = 'Company Orgs is required';
      }
    }

    if (
      !formValue?.primaryOperationalAdmin ||
      formValue?.primaryOperationalAdmin?.length === 0
    ) {
      errors.primaryOperationalAdmin = 'Primary Operational Admin is required';
    } else {
      let userHas3roles: SelectOption[] = [];
      formValue?.primaryOperationalAdmin?.forEach((item: SelectOption) => {
        if (userExistsInAllRole(item?.value)) {
          userHas3roles.push(item);
        }
      });

      formValue?.secondaryOperationalAdmin?.forEach((item: SelectOption) => {
        if (userExistsInAllRole(item?.value)) {
          userHas3roles.push(item);
        }
      });

      if (userHas3roles.length) {
        errors.operationalAdminRole = {
          message: 'Not allowed to add more than 2 roles to the same user',
          users: userHas3roles
        };
      }
    }

    setValidation(errors);
    return Object.keys(errors).length === 0;
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();

    setIsSubmitted(true);

    if (!checkValidation()) {
      return;
    }

    const { companyType } = companyDetail;

    const orgsAddRemovedPayload = getAddRemovedPayload(
      companyDetail?.organizations || [],
      formValue?.organizations || []
    );

    const countriesAddRemovedPayload = getAddRemovedPayload(
      companyDetail?.countries?.map(({ value }: SelectOption) => value) || [],
      formValue?.countries?.map(({ value }: SelectOption) => value) || []
    );

    const customersAddRemovedPayload = getAddRemovedPayload(
      companyDetail?.customers?.map(({ value }: SelectOption) => value) || [],
      formValue?.customers?.map(({ value }: SelectOption) => value) || []
    );

    const payload = {
      companyId,
      companyName: formValue?.companyName,
      companyTypeId:
        formValue?.companyTypeId ||
        allCompanyTypes?.find(
          (item: CompanyType) => formValue?.companyType == item.companyTypeName
        )?.id,
      modifiedCompanyType: formValue?.companyType,
      currentCompanyType: companyType,
      companyNodeId: formValue?.companyNodeId,
      countries:
        formValue?.countries?.map(({ value }: SelectOption) => value) || [],
      customers:
        formValue?.customers?.map(({ value }: SelectOption) => value) || [],
      organizations: formValue?.organizations || [],
      primaryOperationalAdmin:
        formValue?.primaryOperationalAdmin?.map((item: SelectOption) => {
          return {
            userId: item?.userId,
            email: item?.email,
            firstName: item?.firstName,
            lastName: item?.lastName
          };
        }) || [],
      secondaryOperationalAdmin:
        formValue?.secondaryOperationalAdmin?.map((item: SelectOption) => {
          return {
            userId: item?.userId,
            email: item?.email,
            firstName: item?.firstName,
            lastName: item?.lastName
          };
        }) || [],
      addOrgsList: orgsAddRemovedPayload.addedList,
      removeOrgsList: orgsAddRemovedPayload.removedList,
      addCountriesList: countriesAddRemovedPayload.addedList,
      removeCountriesList: countriesAddRemovedPayload.removedList,
      addCustomersList: customersAddRemovedPayload.addedList,
      removeCustomersList: customersAddRemovedPayload.removedList
    };

    setLoading(true);
    try {
      const res = await updateCompanyType(payload);
      if (companyId) {
        const companyData = await fetchExistingCompanySearch(companyId);
        setLoading(false);
        updateFormValue(companyData);
      }
    } catch (err: any) {
      setLoading(false);
      notify(err.message, 'error');
    }
  };

  return (
    <>
      <Dialog
        open={open}
        size={''}
        onDialogClose={dialogClose}
        closeOnClickOutside={false}
        title={`Update Company Type`}
        body={
          <form onSubmit={handleSubmit}>
            {loading ||
            getCustomersStatus === 'PENDING' ||
            getOrganizationStatus === 'PENDING' ||
            getCountriesStatus === 'PENDING' ||
            getAllCompanyTypesStatus === 'PENDING' ||
            getOperationalAdminStatus === 'PENDING' ? (
              <Loader />
            ) : (
              <>
                <div className="row">
                  <div className="col-md-6 half-margin-bottom half-margin-top">
                    <div className="form-group__text">
                      <label
                        htmlFor="select-companyType"
                        className=" text-weight-700 required"
                      >
                        <strong>Company Type</strong>
                      </label>
                      <Select
                        isClearable
                        value={
                          formValue?.companyType
                            ? {
                                value: formValue?.companyType,
                                label: formValue?.companyType
                              }
                            : undefined
                        }
                        name="companyType"
                        options={
                          allCompanyTypes?.length
                            ? allCompanyTypes.map((item: CompanyType) => {
                                return {
                                  value: item.id,
                                  label: item.companyTypeName,
                                  data: item
                                };
                              })
                            : []
                        }
                        className="basic-multi-select"
                        classNamePrefix="select"
                        onChange={(selectedValues: SelectOption | undefined) =>
                          onCompanyTypeChange(selectedValues)
                        }
                      />
                      {isSubmitted &&
                        validation &&
                        validation['companyType'] && (
                          <span className="text-danger text-size-12">
                            {validation['companyType']}
                          </span>
                        )}
                    </div>
                  </div>
                  {formValue?.isCountryShow ? (
                    <>
                      <div className="col-md-6 half-margin-bottom half-margin-top">
                        <div className={`form-group`}>
                          <div className="form-group__text">
                            <label
                              htmlFor="input-type-countries-name"
                              className={`text-weight-700 ${
                                formValue?.isCountryMandatory
                                  ? 'required'
                                  : null
                              }`}
                            >
                              Countries
                            </label>

                            <Select
                              value={formValue?.countries}
                              isMulti
                              name="countries"
                              options={
                                countries?.length
                                  ? countries?.map((item: any) => {
                                      return {
                                        value: item,
                                        label: item
                                      };
                                    })
                                  : []
                              }
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={onCountriesChange}
                            />
                            {isSubmitted &&
                              validation &&
                              validation['countries'] && (
                                <span className="text-danger text-size-12">
                                  {validation['countries']}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}

                  {formValue?.isCustomerShow ? (
                    <>
                      <div className="col-md-6 half-margin-bottom half-margin-top">
                        <div className={`form-group`}>
                          <div className="form-group__text">
                            <label
                              htmlFor="input-type-customers-name"
                              className={`text-weight-700 ${
                                formValue?.isCustomerMandatory
                                  ? 'required'
                                  : null
                              }`}
                            >
                              Customers
                            </label>

                            <Select
                              value={formValue?.customers}
                              isMulti
                              name="customers"
                              options={
                                customers?.length
                                  ? customers?.map((item: any) => {
                                      return {
                                        value: item,
                                        label: item
                                      };
                                    })
                                  : []
                              }
                              className="basic-multi-select"
                              classNamePrefix="select"
                              onChange={(selectedValues: any) =>
                                onSelectorChange(
                                  selectedValues || [],
                                  'customers'
                                )
                              }
                            />
                            {isSubmitted &&
                              validation &&
                              validation['customers'] && (
                                <span className="text-danger text-size-12">
                                  {validation['customers']}
                                </span>
                              )}
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  {formValue?.isOrgShow ? (
                    <div className="col-md-6 half-margin-bottom half-margin-top">
                      <div className="form-group">
                        <div className="form-group__text">
                          <label
                            htmlFor="select-additionalOrganization"
                            className={`text-weight-700 ${
                              formValue?.isOrgMandatory ? 'required' : ''
                            }`}
                          >
                            Company Orgs
                          </label>
                          <Select
                            value={
                              formValue?.organizations?.map((item: any) => {
                                return {
                                  value: item,
                                  label: item
                                };
                              }) || []
                            }
                            isMulti
                            name="organizations"
                            options={
                              organizationsOptions?.length
                                ? organizationsOptions.map((item: any) => {
                                    return {
                                      value: item?.organizationCode,
                                      label: `${item?.organizationName} (${item?.organizationCode})`
                                    };
                                  })
                                : []
                            }
                            className="basic-multi-select"
                            classNamePrefix="select"
                            onChange={organizationsChange}
                          />

                          {isSubmitted &&
                            validation &&
                            validation['organizations'] && (
                              <span className="text-danger text-size-12">
                                {validation['organizations']}
                              </span>
                            )}
                        </div>
                      </div>
                    </div>
                  ) : null}

                  <div className="col-md-6 half-margin-bottom half-margin-top">
                    <div className={`form-group`}>
                      <div className="form-group__text">
                        <label
                          htmlFor="select-additionalOrganization"
                          className={`text-weight-700 required`}
                        >
                          Primary Operational Admin
                        </label>
                        <OrgSelect
                          value={
                            formValue?.primaryOperationalAdmin?.length
                              ? formValue?.primaryOperationalAdmin[0]
                              : undefined
                          }
                          name="primaryOperationalAdmin"
                          options={
                            operationalAdminList?.length
                              ? operationalAdminList
                              : []
                          }
                          components={{
                            Option: UserSelectOption
                          }}
                          className="basic-multi-select"
                          classNamePrefix="select"
                          onChange={primaryOperationalAdminChange}
                          isLoading={getOperationalAdminStatus === 'PENDING'}
                        />

                        {isSubmitted &&
                          validation &&
                          validation['primaryOperationalAdmin'] && (
                            <span className="text-danger text-size-12">
                              {validation['primaryOperationalAdmin']}
                            </span>
                          )}

                        {isSubmitted &&
                          validation &&
                          validation['operationalAdminRole'] && (
                            <div className="alert alert--warning">
                              <div className="alert__icon icon-warning-outline"></div>
                              <div className="alert__message">
                                {validation['operationalAdminRole']?.message}

                                {validation['operationalAdminRole']?.users
                                  ? ' - ' +
                                    validation['operationalAdminRole']?.users
                                      ?.map((item: SelectOption) => item.value)
                                      .join(' ,')
                                  : null}
                              </div>
                            </div>
                          )}
                      </div>
                    </div>
                  </div>

                  <div className="col-md-6 half-margin-bottom half-margin-top">
                    <div className={`form-group`}>
                      <div className="form-group__text">
                        <label
                          htmlFor="select-SecondaryOperationalAdmin"
                          className={`text-weight-700`}
                        >
                          Secondary Operational Admin
                        </label>
                        <div>
                          <UsersListValue
                            users={
                              formValue?.secondaryOperationalAdmin?.length
                                ? formValue?.secondaryOperationalAdmin?.map(
                                    (item: any) => {
                                      return {
                                        value: item?.pamUserId,
                                        label: item?.pamUserId,
                                        userId: item?.pamUserId,
                                        email: item?.email,
                                        firstName: item?.firstName,
                                        lastName: item?.lastName
                                      };
                                    }
                                  )
                                : []
                            }
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="flex flex-right base-margin-top">
                  <button
                    type="button"
                    onClick={() => onChange(false)}
                    className="btn btn--ghost"
                  >
                    Cancel
                  </button>

                  <button
                    type="submit"
                    className="btn btn--ghost"
                    disabled={
                      arraysContainSameElements(
                        companyDetail?.companyType,
                        formValue?.companyType
                      ) &&
                      arraysContainSameElements(
                        companyDetail?.countries?.map(
                          ({ value }: any) => value
                        ),
                        formValue?.countries?.map(({ value }: any) => value)
                      ) &&
                      arraysContainSameElements(
                        companyDetail?.customers?.map(
                          ({ value }: any) => value
                        ),
                        formValue?.customers?.map(({ value }: any) => value)
                      ) &&
                      arraysContainSameElements(
                        companyDetail?.organizations,
                        formValue?.organizations
                      ) &&
                      arraysContainSameElements(
                        (companyDetail as any)?.primaryOperationalAdmin?.map(
                          ({ email }: any) => email
                        ),
                        formValue?.primaryOperationalAdmin?.map(
                          ({ email }: any) => email
                        )
                      )
                    }
                  >
                    Save
                  </button>
                </div>
              </>
            )}
          </form>
        }
        footer={<></>}
      />
    </>
  );
};

export default CompanyTypeDialog;
