import React, { useState, useEffect } from 'react';

import Loader from '../../atoms/Loader';
import Dialog from '../../atoms/Dialog';
import { hasAccess } from '../../../helpers';
import UsersListValue from '../../atoms/UsersListValue';
import InvitePaListValue from '../../atoms/InvitePaListValue/InvitePaListValue';

/* const Vendor = (companyDetail) => {
  if (companyDetail.index == -1 || companyDetail.index == '') {
    return null;
  }

  const { index, lsCompanyNodesDetails, companyType } = companyDetail;

  const {
    lsPADetails,
    lsPAMDetails,
    sponsor,
    vendorId,
    vendorName,
    lsOPAdmin,
    supplierSiteId,
    lsCompanyOrgs,
    companyGroupDetails,
    lsSponsorAdmin,
    lsCompanyCountries,
    lsCompanyCustomers
  } = lsCompanyNodesDetails[index];

  return (
    <div className="card-body">
      <div className="row">
        <div className="col-md-4">
          <div className="record-info">
            <span className="record-label">Vendor Id</span>
            <div>
              <span className="record-value">{vendorId && vendorId}</span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="record-info">
            <span className="record-label">Site Id</span>
            <div>
              <span className="record-value">
                {supplierSiteId && supplierSiteId}
              </span>
            </div>
          </div>
        </div>
        <div className="col-md-4">
          <div className="record-info">
            <span className="record-label">Group Name</span>
            <div>
              <span className="record-value">
                {companyGroupDetails?.companyGroupName &&
                  companyGroupDetails?.companyGroupName}
              </span>
            </div>
          </div>
        </div>

        <div className="col-md-4">
          <div className="row">
            <div className="col-md-12">
              <div className="record-info">
                <span className="record-label">Sponsor</span>
                <div>
                  <span className="record-value">
                    <UsersListValue
                      users={
                        lsSponsorAdmin?.map((item) => {
                          return {
                            userId: item?.sponsor,
                            email: item?.email,
                            firstName: item?.firstName,
                            lastName: item?.lastName
                          };
                        }) || []
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="record-info">
                <span className="record-label">Partner Account Managers</span>
                <div>
                  <span className="record-value">
                    <UsersListValue
                      users={
                        lsPAMDetails?.map((item) => {
                          return {
                            userId: item?.userId,
                            email: item?.email,
                            firstName: item?.firstName,
                            lastName: item?.lastName,
                            activeFlag: item?.activeFlag,
                            status: item?.status,
                            remarks: item?.remarks
                          };
                        }) || []
                      }
                    />
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>

        {hasAccess('company', 'readOperationalAdmin') ? (
          <div className="col-md-4">
            <div className="row">
              <div className="col-md-12">
                <div className="record-info">
                  <span className="record-label">
                    Primary Operational Admin
                  </span>
                  <div>
                    <span className="record-value">
                      <UsersListValue
                        users={
                          lsOPAdmin
                            ?.filter(({ primary }) => {
                              return primary;
                            })
                            ?.map((item) => {
                              return {
                                userId: item?.pamUserId,
                                email: item?.email,
                                firstName: item?.firstName,
                                lastName: item?.lastName,
                                status: item?.status,
                                remarks: item?.remarks
                              };
                            }) || []
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-md-12">
                <div className="record-info">
                  <span className="record-label">
                    Secondary Operational Admin
                  </span>
                  <div>
                    <span className="record-value">
                      <UsersListValue
                        users={
                          lsOPAdmin
                            ?.filter(({ primary }) => {
                              return !primary;
                            })
                            ?.map((item) => {
                              return {
                                userId: item?.pamUserId,
                                email: item?.email,
                                firstName: item?.firstName,
                                lastName: item?.lastName
                              };
                            }) || []
                        }
                      />
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}; */

const CompanyOnboardView = ({
  companyDetail,
  buttonTitle = null,
  open: isDialogOpen = false,
  handleNext,
  onChange,
  loading = false
}) => {
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [activeVendorIndex, setActiveVendorIndex] = useState(0);
  const [value, setValue] = useState({
    searchBy: '-1'
  });

  const [companyData, setCompanyData] = useState([]);
  const [className, setClassName] = useState({
    'Company Details': {},
    'Admin Details': {},
    'Operational Admin Details': {}
  });

  useEffect(() => {
    const {
      companyName,
      companyType,
      dunsNumber,
      addressLine1,
      addressLine2,
      city,
      state,
      country,
      postalCode,
      domainNames: emailDomains,
      lsCompanyNodesDetails,
      lsPA,
      lsPARequestDetails,
      isOrgMandatory,
      isCountryMandatory,
      isCustomerMandatory,
      isOrgShow,
      isCountryShow,
      isCustomerShow
    } = companyDetail;

    let companyDetailsObj = {
      'Company Name': companyName,
      'Vendor Id': '-',
      'Site Id': '-',
      'DUNS Number': dunsNumber,
      'Address Line 1': addressLine1,
      'Address Line 2': addressLine2,
      City: city,
      State: state,
      Country: country,
      'Zip/Postal Code': postalCode,
      'Email domains': emailDomains?.join(', '),
      'Group Name': '-',
      'Company Type': companyType,
      Countries: '-',
      Customers: '-',
      'Company Orgs': '-'
    };

    const adminDetailsObj = {
      'Partner Administrators': (
        <UsersListValue
          users={
            lsPA?.map((item) => {
              return {
                userId: item?.email,
                email: item?.email,
                firstName: item?.firstName,
                lastName: item?.lastName,
                activeFlag: item?.activeFlag,
                status: item?.status,
                remarks: item?.remarks
              };
            }) || []
          }
        />
      ),
      'Invited Partner Administrators': (
        <InvitePaListValue
          users={
            lsPARequestDetails?.map((item) => {
              return {
                userId: item?.email,
                email: item?.email,
                firstName: item?.firstName,
                lastName: item?.lastName,
                status: item?.status,
                comment: item?.comment,
                creationDate: item?.creationDate
              };
            }) || []
          }
        />
      ),
      'Partner Account Managers': '-',
      Sponsor: '-'
    };

    const operationAdminDetailsObj = {
      'Primary Operational Admin': '-',
      'Secondary Operational Admin': '-'
    };

    if (
      lsCompanyNodesDetails &&
      lsCompanyNodesDetails.length > 0 &&
      lsCompanyNodesDetails[activeVendorIndex]
    ) {
      const {
        vendorId,
        supplierSiteId,
        lsCompanyOrgs,
        companyGroupDetails,
        lsCompanyCountries,
        lsCompanyCustomers,
        lsSponsorAdmin,
        lsPAMDetails,
        lsOPAdmin
      } = lsCompanyNodesDetails[activeVendorIndex];
      companyDetailsObj['Vendor Id'] = vendorId;
      companyDetailsObj['Site Id'] = supplierSiteId;
      companyDetailsObj['Group Name'] = companyGroupDetails?.companyGroupName;

      if (isOrgShow) {
        companyDetailsObj['Company Orgs'] =
          lsCompanyOrgs?.map((item) => item.org)?.join(', ') || '-';
      } else {
        delete companyDetailsObj['Company Orgs'];
      }

      if (isCountryShow) {
        companyDetailsObj['Countries'] =
          lsCompanyCountries?.map((item) => item.country)?.join(', ') || '-';
      } else {
        delete companyDetailsObj['Countries'];
      }

      if (isCustomerShow) {
        companyDetailsObj['Customers'] =
          lsCompanyCustomers?.map((item) => item.customer)?.join(', ') || '-';
      } else {
        delete companyDetailsObj['Customers'];
      }

      // admin details
      adminDetailsObj['Sponsor'] = (
        <UsersListValue
          users={
            lsSponsorAdmin?.map((item) => {
              return {
                userId: item?.sponsor,
                email: item?.email,
                firstName: item?.firstName,
                lastName: item?.lastName
              };
            }) || []
          }
        />
      );

      adminDetailsObj['Partner Account Managers'] = (
        <UsersListValue
          users={
            lsPAMDetails?.map((item) => {
              return {
                userId: item?.userId,
                email: item?.email,
                firstName: item?.firstName,
                lastName: item?.lastName,
                activeFlag: item?.activeFlag,
                status: item?.status,
                remarks: item?.remarks
              };
            }) || []
          }
        />
      );
      if (hasAccess('company', 'readOperationalAdmin')) {
        operationAdminDetailsObj['Primary Operational Admin'] = (
          <UsersListValue
            users={
              lsOPAdmin
                ?.filter(({ primary }) => {
                  return primary;
                })
                ?.map((item) => {
                  return {
                    userId: item?.pamUserId,
                    email: item?.email,
                    firstName: item?.firstName,
                    lastName: item?.lastName,
                    status: item?.status,
                    remarks: item?.remarks
                  };
                }) || []
            }
          />
        );
        operationAdminDetailsObj['Secondary Operational Admin'] = (
          <UsersListValue
            users={
              lsOPAdmin
                ?.filter(({ primary }) => {
                  return !primary;
                })
                ?.map((item) => {
                  return {
                    userId: item?.pamUserId,
                    email: item?.email,
                    firstName: item?.firstName,
                    lastName: item?.lastName
                  };
                }) || []
            }
          />
        );
      } else {
        delete operationAdminDetailsObj['Primary Operational Admin'];
        delete operationAdminDetailsObj['Secondary Operational Admin'];
      }
    }

    setClassName((prev) => {
      const obj = {
        ...prev,
        'Admin Details': {
          ...prev['Admin Details'],
          'Partner Administrators': 'col-xxl-6 col-xl-6 col-lg-6 col-md-6',
          'Invited Partner Administrators':
            'col-xxl-6 col-xl-6 col-lg-6 col-md-6',
          Sponsor: 'col-xxl-6 col-xl-6 col-lg-6 col-md-6',
          'Partner Account Managers': 'col-xxl-6 col-xl-6 col-lg-6 col-md-6'
        },
        'Operational Admin Details': {
          ...prev['Operational Admin Details'],
          'Primary Operational Admin': 'col-xxl-6 col-xl-6 col-lg-6 col-md-6',
          'Secondary Operational Admin': 'col-xxl-6 col-xl-6 col-lg-6 col-md-6'
        }
      };
      return obj;
    });

    const companyDataObj = {
      'Company Details': companyDetailsObj,
      'Admin Details': adminDetailsObj,
      'Operational Admin Details': operationAdminDetailsObj
    };

    if (!hasAccess('company', 'readOperationalAdmin')) {
      delete companyDataObj['Operational Admin Details'];
    }

    setCompanyData(companyDataObj);
  }, [companyDetail, activeVendorIndex]);

  const { companyName, lsCompanyNodesDetails } = companyDetail;

  useEffect(() => {
    if (lsCompanyNodesDetails && lsCompanyNodesDetails.length > 0) {
      setValue({ ...value, searchBy: '0' });
    }
  }, [lsCompanyNodesDetails]);

  useEffect(() => {
    setIsLoading(loading);
  }, [loading]);

  useEffect(() => {
    setOpen(isDialogOpen);
  }, [isDialogOpen]);

  const dialogOpen = () => {
    setOpen(true);
    if (onChange) onChange(true);
  };

  const handleChange = (event) => {
    setValue({ ...value, ...{ [event.target.name]: event.target.value } });
  };

  const dialogClose = () => {
    setOpen(false);
    if (onChange) onChange(false);
  };

  return (
    <>
      {buttonTitle && <a onClick={dialogOpen}>{buttonTitle}</a>}
      <Dialog
        open={open}
        size="fluid"
        onDialogClose={dialogClose}
        closeOnClickOutside={false}
        title={`Company Name: ${companyName}`}
        body={
          <div>
            {isLoading && (
              <div className="center-holder-wrap">
                <Loader />
              </div>
            )}
            {Object.entries(companyData).map(([key, value]) => (
              <div key={key} className="card half-margin-bottom">
                <div className="card-header">
                  <h5 className="text-primary">{key}</h5>
                </div>
                <div className="card-body">
                  <div className="row">
                    {Object.entries(value).map(([title, value], index) => {
                      return (
                        <div
                          key={`${value}${index}`}
                          className={
                            className[key][title] ||
                            `col-xxl-3 col-xl-3 col-lg-3 col-md-6`
                          }
                        >
                          <div className="record-info">
                            <span className="record-label">{title}</span>
                            <div>
                              <span className="record-value">
                                {value || '-'}
                              </span>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              </div>
            ))}
          </div>
        }
      />
    </>
  );
};

export default CompanyOnboardView;
