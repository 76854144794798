import React, { useState, useEffect } from 'react';

import Dialog from '../../atoms/Dialog';

const UserDetailDialog = ({
  userDetail,
  searchUserType,
  buttonTitle,
  open: isDialogOpen = false,
  onChange = (status = false) => {}
}) => {
  const [open, setOpen] = useState(false);

  const {
    userId,
    firstname,
    lastname,
    email,
    userType,
    mcocompany,
    vendorId,
    primaryOrg,
    secOrg,
    companyPhoneNumber,
    profileStatus,
    companyAddress1,
    companyAddress2,
    mcompanytype,
    dunsNo,
    registeredDate,
    PA,
    manager,
    title,
    companyState,
    countries,
    customers,
    country,
    postalCode,
    department,
    employeeNumber,
    designationName,
    supplierSiteId,
    organizations
  } = userDetail;
  useEffect(() => {
    setOpen(isDialogOpen);
  }, [isDialogOpen]);

  const dialogOpen = () => {
    setOpen(true);
    if (onChange) onChange(true);
  };

  const dialogClose = () => {
    setOpen(false);
    if (onChange) onChange(false);
  };

  return (
    <>
      {buttonTitle && <a onClick={dialogOpen}>{buttonTitle}</a>}
      <Dialog
        open={open}
        size="fluid"
        onDialogClose={dialogClose}
        closeOnClickOutside={false}
        title={`View User : ${firstname} ${lastname}`}
        body={
          <div>
            <div className="card">
              <div className="card-header">
                <h5 className="text-primary">User Details</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <div className="record-info">
                      <span className="record-label">User Id</span>
                      <div>
                        <span className="record-value">{userId}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <div className="record-info">
                      <span className="record-label">First Name</span>
                      <div>
                        <span className="record-value">{firstname}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Last Name</span>
                      <div>
                        <span className="record-value">{lastname}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Email</span>
                      <div>
                        <span className="record-value">{email}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Status</span>
                      <div>
                        <span className="record-value">
                          {searchUserType == 'cec' ? 'ACTIVE' : profileStatus}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Cisco Manager</span>
                      <div>
                        <span className="record-value">{manager}</span>
                      </div>
                    </div>
                  </div>
                  {searchUserType === 'cec' ? (
                    <>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                        <div className="record-info">
                          <span className="record-label">Title</span>
                          <div>
                            <span className="record-value">
                              {title?.trim() != '0' ? title : ''}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                        <div className="record-info">
                          <span className="record-label">Department</span>
                          <div>
                            <span className="record-value">{department}</span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                        <div className="record-info">
                          <span className="record-label">Employee Number</span>
                          <div>
                            <span className="record-value">
                              {employeeNumber}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : null}
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Designation</span>
                      <div>
                        <span className="record-value">{designationName}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* <div className="card qtr-margin-top">
              <div className="card-header">
                <h5 className="text-primary">Address</h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Address Line 1</span>
                      <div>
                        <span className="record-value">
                          {' '}
                          {companyAddress1?.trim() != '0'
                            ? companyAddress1
                            : ''}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Address Line 2</span>
                      <div>
                        <span className="record-value">
                          {companyAddress2?.trim() != '0'
                            ? companyAddress2
                            : ''}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <div className="record-info">
                      <span className="record-label">ZIP/Postal Code</span>
                      <div>
                        <span className="record-value">
                          {' '}
                          {postalCode?.trim() != '0' ? postalCode : ''}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <div className="record-info">
                      <span className="record-label">State</span>
                      <div>
                        <span className="record-value">
                          {companyState?.trim() != '0' ? companyState : ''}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Country</span>
                      <div>
                        <span className="record-value">
                          {country?.trim() != '0' ? country : ''}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Phone No</span>
                      <div>
                        <span className="record-value">
                          {' '}
                          {companyPhoneNumber?.trim() != '0'
                            ? companyPhoneNumber
                            : ''}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div> */}

            <div className="card qtr-margin-top">
              <div className="card-header">
                <h5 className="text-primary">Company Details </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Company Name</span>
                      <div>
                        <span className="record-value">{mcocompany}</span>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Vendor Id</span>
                      <div>
                        <span className="record-value">{vendorId}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Site Id</span>
                      <div>
                        <span className="record-value">{supplierSiteId}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                    <div className="record-info">
                      <span className="record-label">DUNS Number</span>
                      <div>
                        <span className="record-value">{dunsNo}</span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Company Type</span>
                      <div>
                        <span className="record-value">
                          {mcompanytype || '-'}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Countries</span>
                      <div>
                        <span className="record-value">
                          {countries?.length ? countries?.join(', ') : '-'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Customers</span>
                      <div>
                        <span className="record-value">
                          {customers?.length ? customers?.join(', ') : '-'}
                        </span>
                      </div>
                    </div>
                  </div>
                  {searchUserType === 'cco' ? (
                    <>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                        <div className="record-info">
                          <span className="record-label">
                            Primary Organization
                          </span>
                          <div>
                            <span className="record-value">
                              {primaryOrg || '-'}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                        <div className="record-info">
                          <span className="record-label">
                            Secondary Organization
                          </span>
                          <div>
                            <span className="record-value">
                              {secOrg || '-'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                      <div className="record-info">
                        <span className="record-label">Company Orgs</span>
                        <div>
                          <span className="record-value">
                            {organizations?.length
                              ? organizations?.join(', ')
                              : '-'}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                    <div className="record-info">
                      <span className="record-label">
                        Partner Administrators
                      </span>
                      <div>
                        <span className="record-value">
                          {Array.isArray(PA) ? PA?.join(', ') : PA}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        }
        footer={null}
      />
    </>
  );
};

export default UserDetailDialog;
