import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { fetchExternalUserDetails } from '../../../services/UsersService';
import FullScreenLoader from '../../molecules/FullScreenLoader';
import UserActionDialog from '../../organisms/UserActionDialog';
import { hasAccessForResourceRole } from '../../../helpers';
import { isItemInArrayIgnoreCase } from '../../../helpers/common';
import ExternalUserDesignationDetail from './ExternalUserDesignationDetail';

export interface ExternalUserDetailProps {
  userId: string;
  action?: string;
}

const ExternalUserDetail = ({
  userId,
  action: pageAction
}: ExternalUserDetailProps) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const authData = useSelector((state: any) => state.auth);
  const { actualUserType, user, userActiveRolePermissions } = authData;

  const searchUserType = 'cco';
  const [userDetail, setUserDetail] = useState<any>({});
  const [isLoading, setIsLoading] = useState(false);
  const [actionDialogOpen, setActionDialogOpen] = useState<boolean>(false);
  const [selectedDesignationRows, setSelectedDesignationRows] = useState<any>(
    []
  );

  useEffect(() => {
    if (userId) {
      getUserDetails();
    }
  }, [userId]);

  const getUserDetails = async () => {
    try {
      setIsLoading(true);

      const result = await fetchExternalUserDetails({
        searchUserId: userId,
        userType: actualUserType
          ? actualUserType?.map((item: any) => item?.toUpperCase())
          : [],
        includeInactiveUsers: true
      });

      const userDetailsResult =
        result?.data && result?.data?.length ? result?.data[0] : {};

      if (userDetailsResult && Object.entries(userDetailsResult).length === 0) {
        navigate('/page-not-found');
      }

      setUserDetail(userDetailsResult);

      dispatch({
        type: 'GET_EXTERNAL_USERS_LIST_UPDATE',
        payload: userDetailsResult
      });

      setIsLoading(false);
    } catch (err) {
      setIsLoading(false);
    }
  };

  const onUserUpdate = () => {
    getUserDetails();
  };

  const onDeactivateUserUpdate = () => {
    getUserDetails();
  };

  return (
    <>
      <div className="card base-margin-top" style={{ marginBottom: '60px' }}>
        <div className="card-header">
          <div className="flex">
            <h5 className="text-primary" style={{ width: '100%' }}>
              User Details
              {userDetail?.userId !== user?.ccoid &&
              userDetail?.profileStatus?.toLowerCase() === 'active' &&
              hasAccessForResourceRole(
                [
                  {
                    roleName: userDetail?.companyTypeGroupName || '',
                    exist: !!userActiveRolePermissions?.find(
                      (roleItem: any) =>
                        roleItem?.name === userDetail?.companyTypeGroupName
                    )
                  },
                  {
                    roleName: 'pam',
                    exist: !!userDetail?.PAM?.includes(user?.ccoid)
                  },
                  {
                    roleName: 'pa',
                    exist: isItemInArrayIgnoreCase(
                      user?.email || '',
                      userDetail?.PA && userDetail?.PA?.length
                        ? userDetail?.PA
                        : []
                    )
                  }
                ],
                'users',
                'deactivate'
              ) ? (
                <UserActionDialog
                  buttonTitle={'Deactivate'}
                  details={userDetail}
                  open={actionDialogOpen}
                  action={'DEACTIVATE'}
                  onChange={(dialogStatus: boolean) => {
                    setActionDialogOpen(dialogStatus);
                  }}
                  onSuccess={onDeactivateUserUpdate}
                />
              ) : null}
            </h5>

            <Link
              to="/cco-users-manage"
              state={{ fromSource: 'externalUserDetails' }}
              style={{ width: '90px' }}
              className="pull-right btn btn--ghost btn--small"
            >
              <span className="icon-arrow-left-tail"></span> Back
            </Link>
          </div>
        </div>
        <div className="card-body">
          {isLoading ? <FullScreenLoader /> : <></>}
          <div>
            <div className="row">
              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div className="record-info">
                  <span className="record-label">User Id</span>
                  <div>
                    <span className="record-value">{userDetail?.userId}</span>
                  </div>
                </div>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div className="record-info">
                  <span className="record-label">First Name</span>
                  <div>
                    <span className="record-value">
                      {userDetail?.firstname}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div className="record-info">
                  <span className="record-label">Last Name</span>
                  <div>
                    <span className="record-value">{userDetail?.lastname}</span>
                  </div>
                </div>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div className="record-info">
                  <span className="record-label">Email</span>
                  <div>
                    <span className="record-value">{userDetail?.email}</span>
                  </div>
                </div>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div className="record-info">
                  <span className="record-label">Status</span>
                  <div>
                    <span className="record-value">
                      {userDetail?.profileStatus}
                    </span>
                  </div>
                </div>
              </div>

              <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-6">
                <div className="record-info">
                  <span className="record-label">Cisco Manager</span>
                  <div>
                    <span className="record-value">{userDetail?.manager}</span>
                  </div>
                </div>
              </div>
            </div>
            <div className="card qtr-margin-top">
              <div className="card-header">
                <h5 className="text-primary">Company Details </h5>
              </div>
              <div className="card-body">
                <div className="row">
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Company Name</span>
                      <div>
                        <span className="record-value">
                          {userDetail?.mcocompany}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Vendor Id</span>
                      <div>
                        <span className="record-value">
                          {userDetail?.vendorId}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Site Id</span>
                      <div>
                        <span className="record-value">
                          {userDetail?.supplierSiteId}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Site Address</span>
                      <div>
                        <span className="record-value">
                          {userDetail?.address1}
                          {userDetail?.address2
                            ? ', ' + userDetail?.address2
                            : null}
                          {userDetail?.city ? ', ' + userDetail?.city : null}
                          {userDetail?.country
                            ? ', ' + userDetail?.country
                            : null}
                          {userDetail?.postalCode
                            ? ', ' + userDetail?.postalCode
                            : null}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                    <div className="record-info">
                      <span className="record-label">DUNS Number</span>
                      <div>
                        <span className="record-value">
                          {userDetail?.dunsNo}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Company Type</span>
                      <div>
                        <span className="record-value">
                          {userDetail?.mcompanytype}
                        </span>
                      </div>
                    </div>
                  </div>

                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Countries</span>
                      <div>
                        <span className="record-value">
                          {userDetail?.countires?.length
                            ? userDetail?.countires?.join(', ')
                            : '-'}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                    <div className="record-info">
                      <span className="record-label">Customers</span>
                      <div>
                        <span className="record-value">
                          {userDetail?.customers?.length
                            ? userDetail?.customers?.join(', ')
                            : '-'}
                        </span>
                      </div>
                    </div>
                  </div>
                  {searchUserType === 'cco' ? (
                    <>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                        <div className="record-info">
                          <span className="record-label">
                            Primary Organization
                          </span>
                          <div>
                            <span className="record-value">
                              {userDetail?.primaryOrg || '-'}
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                        <div className="record-info">
                          <span className="record-label">
                            Secondary Organization
                          </span>
                          <div>
                            <span className="record-value">
                              {userDetail?.secOrg || '-'}
                            </span>
                          </div>
                        </div>
                      </div>
                    </>
                  ) : (
                    <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                      <div className="record-info">
                        <span className="record-label">Company Orgs</span>
                        <div>
                          <span className="record-value">
                            {userDetail?.organizations || '-'}
                          </span>
                        </div>
                      </div>
                    </div>
                  )}
                  <div className="col-xxl-3 col-xl-3 col-lg-3 col-md-6">
                    <div className="record-info">
                      <span className="record-label">
                        Partner Administrators
                      </span>
                      <div>
                        <span className="record-value">
                          {userDetail?.PA?.join(', ')}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {userDetail?.externalUserDesignationCompanies &&
            userDetail?.externalUserDesignationCompanies?.length ? (
              <ExternalUserDesignationDetail
                userDetails={userDetail}
                userDesignations={
                  userDetail?.externalUserDesignationCompanies &&
                  userDetail?.externalUserDesignationCompanies?.length
                    ? userDetail?.externalUserDesignationCompanies?.map(
                        (item: any) => {
                          let modifiedItem = item;
                          modifiedItem.siteAddress = `${
                            item?.address1 ? item?.address1 : ''
                          }${item?.address2 ? ', ' + item?.address2 : ''}${
                            item?.city ? ', ' + item?.city : ''
                          }${item?.state ? ', ' + item?.state : ''}${
                            item?.country ? ', ' + item?.country : ''
                          }${item?.zip ? ', ' + item?.zip : ''}`;
                          return modifiedItem;
                        }
                      )
                    : []
                }
                onUserUpdate={onUserUpdate}
              />
            ) : null}
          </div>
        </div>
      </div>
    </>
  );
};

export default ExternalUserDetail;
