import React, { useState, useEffect, useCallback, useRef } from 'react';
import moment from 'moment';
import { useSelector, useDispatch } from 'react-redux';
import debounce from 'lodash.debounce';
import cloneDeep from 'lodash/cloneDeep';
import { AgGridReact } from 'ag-grid-react';

import 'ag-grid-community/styles/ag-grid.css';
import 'ag-grid-community/styles/ag-theme-alpine.css';

import RequestDetailDialog from '../../molecules/RequestDetailDialog';
import {
  fetchRequesterActiveRequest,
  fetchUserActiveRequest,
  fetchRequestHistory
} from '../../../actions';
import Loader from '../../atoms/Loader';
import RefreshButton from '../../atoms/RefreshButton';

const dateFilterParams = {
  // provide comparator function
  comparator: (filterLocalDateAtMidnight, cellValue) => {
    const valueDate = new Date(cellValue);

    const dateAsString = valueDate
      ? moment(valueDate).format('DD/MM/YYYY')
      : null;

    if (dateAsString == null) {
      return 0;
    }

    // In the example application, dates are stored as dd/mm/yyyy
    // We create a Date object for comparison against the filter date
    const dateParts = dateAsString.split('/');
    const year = Number(dateParts[2]);
    const month = Number(dateParts[1]) - 1;
    const day = Number(dateParts[0]);
    const cellDate = new Date(year, month, day);

    // Now that both parameters are Date objects, we can compare
    if (cellDate < filterLocalDateAtMidnight) {
      return -1;
    } else if (cellDate > filterLocalDateAtMidnight) {
      return 1;
    }
    return 0;
  }
};

const RequestTable = ({
  requestTableType = 'activeRequestorRequest',
  requesterStatusTable = false,
  title,
  requests: requestsData,
  status = '',
  errorMessage = ''
}) => {
  const dispatch = useDispatch();
  const gridRef = useRef(null);
  const [gridApi, setGridApi] = useState(null);

  const [rows, setRows] = React.useState([]);
  const [columns, setColumns] = React.useState([]);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [request, setRequest] = useState();
  const [companiesOption, setCompaniesOption] = useState([]);
  const [getRequestStatus, setGetRequestStatus] = useState(undefined);
  const [requests, setRequests] = useState(undefined);
  const [getRequestErrorMessage, setGetRequestErrorMessage] =
    useState(undefined);

  const [search, setSearch] = useState(null);
  const [finalSearch, setFinalSearch] = useState(null);

  const authData = useSelector((state) => state.auth);
  const { userType: userTypes = [], user, actualUserType } = authData;

  useEffect(() => {
    setRequests(requestsData);
  }, [requestsData]);

  useEffect(() => {
    setGetRequestStatus(status);
  }, [status]);

  useEffect(() => {
    setGetRequestErrorMessage(errorMessage);
  }, [errorMessage]);

  useEffect(() => {
    if (gridApi) {
      if (getRequestStatus == 'PENDING') {
        gridApi.showLoadingOverlay();
      } else {
        if (rows && rows.length === 0) {
          gridApi.showNoRowsOverlay();
        } else {
          gridApi.hideOverlay();
        }
      }
    }
  }, [requests, getRequestStatus, gridApi]);

  useEffect(() => {
    return () => {
      dispatch({
        type: 'REQUEST_HISTORY_RESET'
      });
    };
  }, []);

  const onGridReady = (params) => {
    setGridApi(params.api);
  };

  const debouncedSearchSet = useCallback(
    debounce((nextValue) => setFinalSearch(nextValue), 800),
    []
  );

  useEffect(() => {
    const columnsList = [
      {
        headerName: 'Request Id',
        field: 'requestId',
        filter: true,
        headerTooltip: 'Request Id',
        tooltipField: 'requestId',
        pinned: 'left',
        width: 120
      },
      {
        headerName: 'Requester Name',
        field: 'fullName',
        filter: true,
        headerTooltip: 'Requester Name',
        tooltipField: 'fullName',
        minWidth: 150,
        flex: 1
      },
      {
        headerName: 'Requester Email',
        field: 'email',
        filter: true,
        headerTooltip: 'Requester Email',
        tooltipField: 'email',
        minWidth: 150,
        flex: 1
      },
      {
        headerName: 'Company Name',
        field: 'mcocompany',
        filter: true,
        headerTooltip: 'Company Name',
        tooltipField: 'mcocompany',
        minWidth: 145,
        flex: 1
      },
      {
        headerName: 'Request Status',
        field: 'status',
        filter: true,
        headerTooltip: 'Request Status',
        minWidth: 140,
        flex: 1,
        cellRenderer: (params) => {
          const value = params?.value?.toLowerCase();
          switch (value) {
            case 'pending':
              return (
                <span className="label label--info label--small label--bordered">
                  {params?.value}
                </span>
              );

            case 'approved':
              return (
                <span className="label label--success label--small label--bordered">
                  {params?.value}
                </span>
              );

            case 'cancelled':
              return (
                <span className="label label--warning label--small label--bordered">
                  {params?.value}
                </span>
              );

            case 'denied':
              return (
                <span className="label label--danger label--small label--bordered">
                  {params?.value}
                </span>
              );

            default:
              return (
                <span className="label label--light label--small label--bordered">
                  {params?.value}
                </span>
              );
          }
        }
      },

      {
        headerName: 'Requested Access',
        field: 'resourceName',
        filter: true,
        headerTooltip: 'Requested Access',
        tooltipField: 'resourceName',
        minWidth: 170,
        flex: 1
      },
      {
        headerName: 'Request Date',
        field: 'createdDate',
        filter: 'agDateColumnFilter',
        headerTooltip: 'Request Date',
        cellRenderer: (params) => {
          return params?.value
            ? moment(new Date(params?.value)).format('MM-DD-YYYY')
            : '-';
        },
        filterParams: dateFilterParams,
        minWidth: 130,
        flex: 1
      },
      {
        headerName: 'Expiration Date',
        field: 'expiryDate',
        filter: 'agDateColumnFilter',
        headerTooltip: 'Expiration Date',
        cellRenderer: (params) => {
          return params?.value
            ? moment(new Date(params?.value)).format('MM-DD-YYYY')
            : '-';
        },
        filterParams: dateFilterParams,
        minWidth: 140,
        flex: 1
      },
      {
        headerName: 'Action',
        field: 'action',
        width: 100,
        pinned: 'right',
        cellRenderer: (params) => {
          return (
            <a
              onClick={() => {
                onClickRequestDialogOpen(params.data);
              }}
            >
              View Details
            </a>
          );
        }
      }
    ]
      .map((item) => {
        let itemTemp = item;
        itemTemp.resizable = true;
        // itemTemp.floatingFilter = true;
        itemTemp.suppressMenu = true;

        if (item.field !== 'action') {
          itemTemp.sortable = true;
          itemTemp.enableTooltip = true;
          // itemTemp.flex = 1;
        } else {
          itemTemp.sortable = false;
        }
        if (requesterStatusTable) {
          itemTemp.sortable = false;
          itemTemp.filter = false;
        }
        return itemTemp;
      })
      .filter(({ field }) => {
        if (
          requesterStatusTable &&
          ['fullName', 'email', 'mcocompany'].includes(field)
        ) {
          return false;
        }
        return true;
      });
    setColumns(columnsList);
  }, [companiesOption, gridApi]);

  useEffect(() => {
    const filterableColumn = columns
      .filter(({ field }) => field !== 'action')
      .map(({ field }) => field);
    const requestTemp = cloneDeep(requests?.response || []);
    const res = requestTemp
      .map((item, index) => {
        let itemTemp = item;
        itemTemp.fullName = item?.firstName + ' ' + item?.lastName;

        return { ...itemTemp, ...{ id: index } };
      })
      .filter((item) => {
        if (!finalSearch) {
          return true;
        }
        let valid = false;
        Object.keys(item)
          .filter((key) => filterableColumn.includes(key))
          .forEach((key) => {
            if (key === 'createdDate' || key === 'expiryDate') {
              const itemValue = item[key]
                ? moment(new Date(item[key])).format('MM-DD-YYYY')
                : '-';
              if (
                itemValue
                  .toString()
                  .toLowerCase()
                  .includes(finalSearch.toLowerCase())
              ) {
                valid = true;
              }
            } else if (
              item[key]
                .toString()
                .toLowerCase()
                .includes(finalSearch.toLowerCase())
            ) {
              valid = true;
            }
          });
        return valid;
      });

    setRows(res);
  }, [columns, finalSearch, requests]);

  useEffect(() => {
    const requestsTemp = Object.assign([], [...(requests?.response || [])]);
    const companiesOptionTemp = requestsTemp
      ? requestsTemp.map((item) => item?.mcocompany)
      : [];
    setCompaniesOption([...new Set(companiesOptionTemp)]);
  }, [requests]);

  const onSearchChange = (e) => {
    const value = e.target.value;
    setSearch(value.toLowerCase());
    debouncedSearchSet(value.toLowerCase());
  };

  const onClickRequestDialogOpen = (requestRow) => {
    setRequest(requestRow);
    setDialogOpen(true);
  };

  const getRowStyle = (params) => {
    if (params.node.rowIndex % 2 === 0) {
      return { background: '#f2f2f2' };
    }
  };

  const getOverlayNoRowsTemplate = () => {
    if (requesterStatusTable) {
      return 'No request found';
    } else if (requestTableType == 'activeRequest') {
      return 'No pending supply chain request';
    } else {
      return 'No request history found';
    }
  };

  const refresh = () => {
    if (requestTableType == 'activeRequest') {
      dispatch(
        fetchUserActiveRequest({
          userType: actualUserType
            ? actualUserType?.map((item) => item?.toUpperCase())
            : []
        })
      );
    } else if (requestTableType == 'requestHistory') {
      dispatch(
        fetchRequestHistory(
          actualUserType
            ? actualUserType?.map((item) => item?.toUpperCase())
            : []
        )
      );
    } else if (requestTableType == 'activeRequestorRequest') {
      dispatch(fetchRequesterActiveRequest());
    }
  };

  return (
    <div className="">
      <div
        className="half-padding ag-table-title"
        style={{ borderBottom: 'none' }}
      >
        <div className="table-card-header d-flex align-items-center">
          <div className="table-card-title flex-grow-1">
            {requestTableType === 'activeRequest' && (
              <div className="flex-center-vertical">
                <h5 className="display-5">
                  {requesterStatusTable
                    ? 'Request Status'
                    : 'Requests pending for approval'}
                </h5>
              </div>
            )}
            {title && (
              <div className="flex-center-vertical">
                <h5 className="display-5">{title}</h5>
              </div>
            )}
          </div>
          <div className="flex-shrink-0  d-flex">
            <RefreshButton
              onClick={refresh}
              loading={getRequestStatus == 'PENDING'}
            />
            {!requesterStatusTable && (
              <div className="base-margin-left">
                <div className="form-group input--icon">
                  <div className="form-group__text">
                    <input
                      id="input-type-search"
                      value={search || ''}
                      onChange={onSearchChange}
                      type="search"
                      className="table-search-input"
                    />
                    <button type="button" className="link">
                      <span className="icon-search"></span>
                    </button>
                  </div>
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
      <div
        className={`ag-theme-alpine ${
          requesterStatusTable ? 'min-height-38-table' : ''
        }`}
        style={{ height: !requesterStatusTable ? 470 : '100%', width: '100%' }}
      >
        <AgGridReact
          domLayout={requesterStatusTable ? 'autoHeight' : 'normal'}
          ref={gridRef}
          rowData={getRequestStatus == 'PENDING' ? undefined : rows}
          columnDefs={columns}
          gridOptions={{ suppressContextMenu: true }}
          pagination={!requesterStatusTable}
          paginationPageSize={10}
          onGridReady={onGridReady}
          overlayNoRowsTemplate={getOverlayNoRowsTemplate()}
          loadingOverlayComponent={Loader}
          enableCellTextSelection={true}
          suppressCellFocus={true}
          getRowStyle={getRowStyle}
          headerHeight={38}
          rowHeight={38}
        ></AgGridReact>
      </div>
      <RequestDetailDialog
        requestTableType={requestTableType}
        actualUserType={actualUserType}
        user={user}
        userTypes={userTypes}
        requestDetail={request || {}}
        open={dialogOpen}
        onChange={(dialogStatus) => {
          setDialogOpen(dialogStatus);
        }}
      />
    </div>
  );
};
export default RequestTable;
