import httpUtil from '../helpers/interceptor';
import notify from '../helpers/notification';

export const fetchCompanyTypes = () => {
  return (dispatch) => {
    dispatch({ type: 'COMPANY_TYPE_START' });
    return httpUtil
      .post(`/api/am/dashboard/v1/email/allowableDomainCompanyTypes`)
      .then((res) => {
        dispatch({
          type: 'COMPANY_TYPE_COMPLETE',
          payload: res?.data
            ? res?.data?.map((item) => {
                return {
                  companyTypeId: item?._id,
                  companyTypeName: item?.companyTypeName
                };
              })
            : []
        });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching company types ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'COMPANY_TYPE_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};

export const fetchComapanyList = (federated = false) => {
  let companiesUrl = federated
    ? '/api/am/dashboard/v1/user/federated/companies'
    : '/api/v1/user/nonfederated/companies';

  return (dispatch) => {
    dispatch({ type: 'COMPANY_LIST_START' });
    return httpUtil
      .post(companiesUrl)
      .then((res) => {
        dispatch({
          type: 'COMPANY_LIST_COMPLETE',
          payload: res?.data
            ? res?.data?.map((item) => {
                return federated
                  ? {
                      companyId: item?._id,
                      companyName: item?.companyName,
                      companyTypeId: item?.companyTypeIdRef
                    }
                  : {
                      companyId: item?.companyId,
                      companyName: item?.companyName,
                      companyTypeId: item?.companyTypeIdRef
                    };
              })
            : []
        });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching companies ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'COMPANY_LIST_FAILURE',
          companyListErrorMessage: errorMessage
        });
      });
  };
};

export const fetchOrganization = (parentCompany) => {
  return (dispatch) => {
    dispatch({ type: 'ORG_LIST_START' });
    return httpUtil
      .get(
        `/api/am/dashboard/v1/company/orgs/${encodeURIComponent(parentCompany)}`
      )
      .then((res) => {
        const organizationsList =
          res?.lsCompanyOrg?.map((item) => item?.org) || [];

        dispatch({
          type: 'ORG_LIST_COMPLETE',
          payload: organizationsList
        });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching Organizations ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'ORG_LIST_FAILURE',
          orgListErrorMessage: errorMessage
        });
      });
  };
};

export const profileSubmit = (data, userType = '') => {
  let profileSubmitUrl = '/api/am/dashboard/v1/user/profile/submit';
  if (userType === 'federated') {
    profileSubmitUrl = '/api/am/dashboard/v1/federated/profile/submit';
  }

  return (dispatch) => {
    dispatch({ type: 'PROFILE_SUBMIT_START' });
    return httpUtil
      .post(profileSubmitUrl, data)
      .then((res) => {
        if (res?.status?.toLowerCase() === 'failure') {
          const errorMessage =
            res?.msg || 'An error occurred while submitting request';
          dispatch({
            type: 'PROFILE_SUBMIT_FAILURE',
            errorMessage: errorMessage
          });

          notify(errorMessage, 'error');
        } else {
          dispatch({
            type: 'PROFILE_SUBMIT_COMPLETE',
            payload: res
          });
        }

        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while submitting profile ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'PROFILE_SUBMIT_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};

export const fetchProfile = () => {
  return (dispatch) => {
    dispatch({ type: 'GET_PROFILE_START' });
    return httpUtil
      .get(`/api/v1/user/profile`)
      .then((res) => {
        dispatch({
          type: 'GET_PROFILE_COMPLETE',
          payload: res || undefined
        });
        return res;
      })
      .catch((error) => {
        let errorMessage = '';
        if (error?.response?.status != 401) {
          errorMessage = `An error occurred while fetching user profile ${
            error?.response?.data?.error
              ? ': ' + error?.response?.data?.error
              : ''
          }`;
          notify(errorMessage, 'error');
        }
        dispatch({
          type: 'GET_PROFILE_FAILURE',
          errorMessage: errorMessage
        });
      });
  };
};
