import React, { useEffect, useState } from 'react';
import { Link, useLocation } from 'react-router-dom';
import debounce from 'lodash.debounce';
import { useSelector, useDispatch } from 'react-redux';
import { Tooltip } from 'react-tooltip';

import SelectCompaniesTable from '../../organisms/SelectCompaniesTable';
import Loader from '../../atoms/Loader';

import CompanyFormPreview from '../../molecules/CompanyFormPreview';
import Select, { SelectOption } from '../../molecules/Select';
import AsyncSelect from '../../molecules/Select/AsyncSelect';
import CreatableSelect from '../../molecules/Select/CreatableSelect';
import DomainSelect from '../../molecules/DomainSelect';

import UserSelect, { UserSelectOption } from '../../molecules/UserSelect';
import PAUserEdit from '../../organisms/PAUserEdit';
import httpUtil from '../../../helpers/interceptor';
import {
  fetchCountries,
  fetchCustomers,
  fetchAllOrganization,
  fetchOperationalAdmin,
  fetchAllCompanyTypes,
  companySubmit,
  fetchGroups,
  fetchFederatedCompanyDomains
} from '../../../actions';
import {
  fetchCecUsers,
  fetchSCIAMUsers,
  checkNewPAExist
} from '../../../services';
import UserPaginateSelect from '../../molecules/UserPaginateSelect/UserPaginateSelect';
import TransparentFullScreenLoader from '../../molecules/FullScreenLoader/TransparentFullScreenLoader';
import { CompanyType } from '../../../interfaces';
// const steps = ['Search', 'Associate', 'Contract', 'Company Details'];

export const getParentCompanies = async (search: string) => {
  if (search) {
    const res = await httpUtil.post(
      `/api/am/dashboard/v1/get/company/parentSearch`,
      { searchKey: search }
    );
    return res || [];
  } else {
    return [];
  }
};

export interface Step {
  value: string;
  label: string;
}

const CompanyOnboard = () => {
  const dispatch = useDispatch();
  const location = useLocation();

  const companyOnboardData = useSelector((state: any) => state.onboardCompany);
  const operationalAdminList = useSelector(
    (state: any) => state.onboardCompany?.operationalAdmins
  );
  const {
    countries = [],
    customers = [],
    organizations: organizationsOptions = [],
    allCompanyTypes = [],
    postCompanyStatus,
    groups = [],
    getOrganizationStatus,
    companyDomains,
    getCompanyDomainsStatus,
    getOperationalAdminStatus,
    federatedCompanyDomains,
    getFederatedCompanyDomainsStatus
  } = companyOnboardData;
  const loginUser = useSelector((state: any) => state?.auth?.user);

  const [activeStep, setActiveStep] = React.useState(0);
  const [steps, setSteps] = React.useState<Step[]>([]);
  const [submittedSteps, setSubmittedSteps] = React.useState<any>({});
  const [skipped, setSkipped] = React.useState<any>();
  const [formValue, setFormValue] = React.useState<any>({
    companyType: '',
    isCompanyAssociate: 'no'
  });
  const [validation, setValidation] = React.useState<any>({});
  const [defaultPrimaryOperationalAdmin, setDefaultPrimaryOperationalAdmin] =
    React.useState<any>(undefined);
  const [primaryOperationalAdminOptions, setPrimaryOperationalAdminOptions] =
    React.useState<any>(undefined);

  // const [initialScreen, setInitialScreen] = useState(true);
  const [isPreviewable, setIsPreviewable] = useState(false);
  const [completedSteps, setCompletedSteps] = useState(0);
  const [isAddNewPAOpen, setIsAddNewPAOpen] = useState(false);
  const [isAddNewPAChecking, setIsAddNewPAChecking] = useState(false);
  const [inputValue, setInputValue] = useState('');

  useEffect(() => {
    dispatch(fetchCountries());
    dispatch(fetchCustomers());
    dispatch(fetchAllOrganization());
    dispatch(fetchAllCompanyTypes());
    dispatch(fetchGroups());
    dispatch(fetchFederatedCompanyDomains());
    return () => {
      handleReset();
    };
  }, []);

  useEffect(() => {
    const userInOpList = operationalAdminList?.find((item: SelectOption) => {
      return item?.value === loginUser?.ccoid;
    });
    let primaryOperationalAdminOptionsTemp =
      userInOpList && loginUser
        ? [
            {
              firstName: loginUser?.first_name,
              lastName: loginUser?.last_name,
              userId: loginUser?.ccoid,
              email: loginUser?.email,
              value: loginUser?.ccoid,
              label: loginUser?.ccoid
            }
          ]
        : undefined;

    setDefaultPrimaryOperationalAdmin(primaryOperationalAdminOptionsTemp);

    if (operationalAdminList?.length) {
      const uniquePriOpAdminsOptions = operationalAdminList?.filter(
        (item: SelectOption) =>
          !primaryOperationalAdminOptionsTemp?.find(
            (innerItem: SelectOption) => item.value === innerItem.value
          )
      );
      primaryOperationalAdminOptionsTemp = primaryOperationalAdminOptionsTemp
        ? [...primaryOperationalAdminOptionsTemp, ...uniquePriOpAdminsOptions]
        : uniquePriOpAdminsOptions;
    }

    setPrimaryOperationalAdminOptions(primaryOperationalAdminOptionsTemp);
  }, [loginUser, operationalAdminList]);

  useEffect(() => {
    setFormValue((prevValue: any) => ({
      ...prevValue,
      ...{
        primaryOperationalAdmin: defaultPrimaryOperationalAdmin
          ? defaultPrimaryOperationalAdmin
          : undefined
      }
    }));
  }, [defaultPrimaryOperationalAdmin]);

  useEffect(() => {
    setFormValue((prevValue: any) => ({
      ...prevValue,
      ...{
        secondaryOperationalAdmin: operationalAdminList?.filter(
          (item: SelectOption) =>
            !defaultPrimaryOperationalAdmin?.find(
              (innerItem: SelectOption) => item.value === innerItem.value
            )
        )
      }
    }));
  }, [operationalAdminList, defaultPrimaryOperationalAdmin]);

  const handleReset = () => {
    setCompletedSteps(0);
    setIsPreviewable(false);
    setSubmittedSteps({});
    setValidation({});
    setActiveStep(0);
    setFormValue({
      companyType: '',
      isOrgMandatory: false,
      isCountryMandatory: false,
      isCustomerMandatory: false,
      isOrgShow: false,
      isCountryShow: false,
      isCustomerShow: false,
      isCompanyAssociate: 'no'
    });
    dispatch({ type: 'POST_COMPANY_RESET' });
  };

  // useEffect(() => {
  //   console.log('postCompanyStatus', postCompanyStatus);
  // }, [postCompanyStatus]);

  const companyAssociateChange = (event: any) => {
    let newVal: any = {};
    if (event.target?.value === 'no') {
      newVal = {
        companyType: undefined,
        addressLine1: formValue?.selectedCompany?.addressLine1,
        addressLine2: formValue?.selectedCompany?.addressLine2,
        city: formValue?.selectedCompany?.city,
        state: formValue?.selectedCompany?.state,
        country: formValue?.selectedCompany?.country,
        postalCode: formValue?.selectedCompany?.postalCode,
        emailDomains: undefined,
        pa: undefined
      };
    }
    setFormValue({
      ...formValue,
      ...{
        isCompanyAssociate: event.target?.value,
        parentCompany: undefined,
        companyType: undefined,
        companyTypeId: undefined,
        companyTypeGroupName: undefined,
        addressLine1: undefined,
        addressLine2: undefined,
        city: undefined,
        state: undefined,
        country: undefined,
        postalCode: undefined,
        emailDomains: undefined,
        pa: undefined
      },
      ...newVal
    });
  };

  const isStepOptional = (step: number) => {
    return false;
    // return step === 1;
  };

  const isStepSkipped = (step: number) => {
    return skipped?.has(step);
  };

  const checkStepValidity = (step: number) => {
    const stepName = steps[step]?.value;
    return !(
      validation &&
      stepName &&
      validation[stepName] &&
      Object.keys(validation[stepName]).length
    );
  };

  useEffect(() => {
    if (formValue?.isCompanyAssociate === 'yes') {
      setSteps([
        { value: 'search', label: 'Search' },
        // { value: 'associate', label: 'Associate' },
        { value: 'companyDetails', label: 'Company Details' }
      ]);
    } else {
      setSteps([
        { value: 'search', label: 'Search' },
        // { value: 'associate', label: 'Associate' },
        { value: 'contract', label: 'Contract' },
        { value: 'companyDetails', label: 'Company Details' }
      ]);
    }
  }, [formValue?.isCompanyAssociate]);

  // useEffect(() => {
  //   console.log('validation', validation);
  // }, [validation]);

  const checkValidity = (step: number) => {
    if (!steps[step]?.value) {
      return false;
    }

    let errorObject: any = {};
    if (steps[step]?.value === 'search') {
      if (!formValue?.selectedCompany?.companyName) {
        errorObject['selectedCompany'] = `Please select Company`;
      }
    } else if (steps[step]?.value === 'associate') {
      if (formValue?.isCompanyAssociate === 'yes') {
        const isValid = !!formValue?.parentCompany?.companyName;
        if (!isValid) {
          errorObject['parentCompany'] = `Please select Associate Company`;
        }
      }
    } else if (steps[step]?.value === 'contract') {
      if (formValue?.isCompanyAssociate === 'no') {
        const isValid = formValue?.isMncaContractOnFileWithLegal;
        if (!isValid) {
          errorObject[
            'isMncaContractOnFileWithLegal'
          ] = `Cannot proceed unless the contract has been signed.`;
        }
      }
    } else if (steps[step]?.value === 'companyDetails') {
      let validateObj: { key: string; value: string; isMulti?: boolean }[] = [
        { key: 'emailDomains', value: 'Email Domains', isMulti: true },
        { key: 'companyType', value: 'Company Type' },
        { key: 'pam', value: 'PAM', isMulti: true }
      ];

      const selectedEmailDomains = formValue['emailDomains'];

      if (selectedEmailDomains && selectedEmailDomains?.length) {
        if (
          formValue['isFederatedCompany'] &&
          checkIsNonFederatedDomainsExist(selectedEmailDomains)
        ) {
          errorObject[
            'emailDomains'
          ] = `Please add only federated or non federated domains, both federated & non federated domains are not allowed.`;
        }
      }

      if (formValue['companyType'] && !formValue['companyTypeGroupName']) {
        errorObject[
          'companyTypeGroupName'
        ] = `${formValue['companyType']} company type does not have group name, Cannot able to proceed company on-boarding`;
      }

      if (!formValue['sponsor']?.value) {
        errorObject['sponsor'] = `Sponsor is Required`;
      }

      if (
        formValue['isOrgShow'] &&
        formValue['isOrgMandatory'] &&
        !formValue['organizations']?.length
      ) {
        errorObject['organizations'] = `Company Orgs are Required`;
      }

      if (
        formValue['isCountryShow'] &&
        formValue['isCountryMandatory'] &&
        !formValue['countries']?.length
      ) {
        errorObject['countries'] = `Countries Required`;
      }

      if (
        formValue['isCustomerShow'] &&
        formValue['isCustomerMandatory'] &&
        !formValue['customers']?.length
      ) {
        errorObject['customers'] = `Customers Required`;
      }

      /* Roles validation start */
      const selectedSponsor = formValue['sponsor']
        ? formValue['sponsor']?.value
        : '';
      const selectedPams = formValue['pam'];
      const selectedPOA = formValue['primaryOperationalAdmin'];
      const selectedSOA = formValue['secondaryOperationalAdmin'];
      const selectedAllOperationalAdmin = [
        ...(selectedPOA || []),
        ...(selectedSOA || [])
      ];

      if (!selectedPOA?.length) {
        errorObject[
          'primaryOperationalAdmin'
        ] = `Primary operational admin is required`;
      }

      const valueExistsInAllRole = (val: string) => {
        return (
          selectedSponsor?.includes(val) &&
          selectedPams?.find((item: SelectOption) => item.value === val) &&
          selectedAllOperationalAdmin?.find(
            (item: SelectOption) => item.value === val
          )
        );
      };
      let userHas3roles: SelectOption[] = [];
      selectedPams?.forEach((item: SelectOption) => {
        if (valueExistsInAllRole(item?.value)) {
          userHas3roles.push(item);
        }
      });
      if (userHas3roles.length) {
        errorObject['operationalAdmin'] = {
          message: `Not allowed to add more than 2 roles to the same user`,
          users: userHas3roles
        };
      }
      /* Roles validation end */

      /* PA validation start */
      const selectedNewPA = formValue['newPa'];
      let newPAErrorList: any = [];
      selectedNewPA?.forEach((item: any, index: number) => {
        let paEmail = item?.email?.trim();
        let paItemError: any = [];
        if (!item?.firstName?.trim()) {
          paItemError['firstName'] = 'First name is required';
        }
        if (!item?.lastName?.trim()) {
          paItemError['lastName'] = 'Last name is required';
        }
        if (!paEmail) {
          paItemError['email'] = 'Email is required';
        } else {
          let emailRegexp = /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i;
          if (!emailRegexp.test(paEmail)) {
            paItemError['email'] = 'Invalid Email';
          } else {
            const selectedEmailDomains = formValue['emailDomains'] || [];
            let isEmailValid = false;
            selectedEmailDomains?.forEach((mailDomainItem: string) => {
              if (paEmail.endsWith(mailDomainItem)) {
                isEmailValid = true;
              }
            });
            if (!isEmailValid) {
              paItemError['email'] =
                'PA email domain must be same as added email domain';
            } else {
              const findPAsWithSameMail = selectedNewPA.filter(
                (findItem: any) => findItem?.email?.trim() === paEmail
              );

              if (findPAsWithSameMail.length > 1) {
                paItemError['email'] = 'Duplicate PA mail is added';
              }
            }
          }
        }

        if (Object.keys(paItemError)?.length) {
          newPAErrorList[index] = paItemError;
        }
      });
      if (newPAErrorList?.length) {
        errorObject['newPa'] = newPAErrorList;
      }

      const selectedPA = formValue['pa'];

      let paErrorList: any = [];
      selectedPA?.forEach((item: any, index: number) => {
        let paEmail = item?.email?.trim();
        const selectedEmailDomains = formValue['emailDomains'] || [];
        let isEmailValid = false;
        selectedEmailDomains?.forEach((mailDomainItem: string) => {
          if (paEmail.endsWith(mailDomainItem)) {
            isEmailValid = true;
          }
        });
        if (!isEmailValid) {
          paErrorList[index] = paEmail;
        }
      });
      if (paErrorList?.length) {
        errorObject['pa'] =
          'PA email domain must be same as added email domain - ' +
          paErrorList?.join(', ');
      }

      if (
        !formValue?.isFederatedCompany &&
        !selectedPA?.length &&
        !selectedNewPA?.length
      ) {
        errorObject['pa'] = `PA  is Required`;
      }

      /* PA validation end */

      for (const { key, value, isMulti } of validateObj) {
        if (isMulti) {
          if (!formValue[key]?.length) {
            errorObject[key] = `${value} is Required`;
          }
        } else if (!formValue[key]?.trim()) {
          errorObject[key] = `${value} is Required`;
        }
      }
    }

    setValidation({ ...validation, ...{ [steps[step]?.value]: errorObject } });
    return Object.keys(errorObject).length === 0;
  };

  const handleNext = async (isPreview = false) => {
    if (steps[activeStep]?.value) {
      setSubmittedSteps((preVal: any) => {
        return { ...preVal, ...{ [steps[activeStep]?.value]: 'true' } };
      });
    }

    if (!checkValidity(activeStep)) {
      return;
    }

    let newSkipped = skipped;
    if (isStepSkipped(activeStep)) {
      newSkipped = new Set(newSkipped.values());
      newSkipped.delete(activeStep);
    }

    if (isPreview) {
      if (await validateNewPA(activeStep)) {
        setIsPreviewable(true);
      }
    } else {
      setActiveStep((prevActiveStep) => prevActiveStep + 1);
      setSkipped(newSkipped);
      setCompletedSteps((prevActiveStep) => {
        return prevActiveStep + 1;
      });
    }
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleSkip = () => {
    if (!isStepOptional(activeStep)) {
      throw new Error("You can't skip a step that isn't optional.");
    }

    setActiveStep((prevActiveStep) => prevActiveStep + 1);
    setSkipped((prevSkipped: any) => {
      const newSkipped = new Set(prevSkipped.values());
      newSkipped.add(activeStep);
      return newSkipped;
    });
  };

  const StepIcon = (props: any) => {
    const { active, completed, error, icon } = props;

    return (
      <div
        className={`step ${completed ? 'visited' : ''} ${
          active ? 'active' : ''
        } ${error ? 'error' : 'error'}`}
      >
        <div className="step__icon">{icon}</div>
      </div>
    );
  };

  useEffect(() => {
    handleNext();
  }, [formValue?.selectedCompany?.companyName]);

  useEffect(() => {
    // console.log('formValue', formValue);
    checkValidity(activeStep);
  }, [formValue, activeStep, steps]);

  const selectCompany = (company: any) => {
    setFormValue({
      ...formValue,
      ...(company || {}),
      ...{ selectedCompany: company }
    });
  };

  const handleUserInput = (event: any) => {
    const name = event.target.name;
    const value = event.target.value;
    setFormValue({ ...formValue, ...{ [name]: value } });
  };

  const onChangeCheckBox = (event: any) => {
    const name = event.target.name;
    const { checked } = event.target;
    setFormValue({ ...formValue, ...{ [name]: checked } });
  };

  const onPaListChange = (paUserList: any) => {
    setFormValue({ ...formValue, ...{ newPa: paUserList } });
  };

  useEffect(() => {
    if (formValue?.companyType) {
      dispatch(fetchOperationalAdmin(formValue?.companyType));
    } else {
      dispatch({ type: 'GET_OPERATIONALADMIN_COMPLETE', payload: [] });
    }
  }, [formValue?.companyType]);

  const checkIsFederatedDomain = (selectedDomains: string[]): boolean => {
    const isExist = selectedDomains.find((item: string) =>
      federatedCompanyDomains?.includes(item)
    );
    return !!isExist;
  };

  const checkIsNonFederatedDomainsExist = (
    selectedDomains: string[]
  ): boolean => {
    const isExist = selectedDomains.filter(
      (item: string) => !federatedCompanyDomains?.includes(item)
    );
    return !!isExist.length;
  };

  const onDomainChange = (newValue: SelectOption[] | undefined) => {
    const selectedDomains = newValue?.map((item: any) => item.value) || [];

    setFormValue({
      ...formValue,
      ...{
        emailDomains: selectedDomains,
        isFederatedCompany: checkIsFederatedDomain(selectedDomains)
      }
    });
  };

  const onCompanyTypeChange = (newValue: SelectOption | undefined) => {
    const companyTypeValue = newValue?.label;
    const companyTypeId = newValue?.value;
    const companyTypeGroupName = newValue?.data?.groupName;
    const isOrgMandatory = newValue?.data?.isOrgMandatory;
    const isCountryMandatory = newValue?.data?.isCountryMandatory;
    const isCustomerMandatory = newValue?.data?.isCustomerMandatory;
    const isOrgShow = newValue?.data?.isOrgShow;
    const isCountryShow = newValue?.data?.isCountryShow;
    const isCustomerShow = newValue?.data?.isCustomerShow;

    setFormValue({
      ...formValue,
      ...{
        ['companyType']: companyTypeValue,
        ['companyTypeGroupName']: companyTypeGroupName,
        companyTypeId: companyTypeId,
        isOrgMandatory: isOrgMandatory,
        isCountryMandatory: isCountryMandatory,
        isCustomerMandatory: isCustomerMandatory,
        isOrgShow: isOrgShow,
        isCountryShow: isCountryShow,
        isCustomerShow: isCustomerShow,
        organizations: undefined,
        countries: undefined,
        customers: undefined,
        primaryOperationalAdmin: defaultPrimaryOperationalAdmin
          ? defaultPrimaryOperationalAdmin
          : undefined,
        secondaryOperationalAdmin: undefined
      }
    });
  };

  const onSelectorChange = (
    newValue:
      | SelectOption
      | SelectOption[]
      | string[]
      | string
      | undefined
      | number,
    name: string
  ) => {
    setFormValue({
      ...formValue,
      ...{
        [name]: newValue
      }
    });
  };

  const onCountriesChange = (newValue = []) => {
    const selectedVal = newValue?.map((item: any) => item.value) || [];
    setFormValue({
      ...formValue,
      ...{
        countries: selectedVal
      }
    });
  };

  const organizationChange = (newValue = []) => {
    const selectedOrg = newValue?.map((item: any) => item.value) || [];

    setFormValue({
      ...formValue,
      ...{
        organizations: selectedOrg
      }
    });
  };

  const primaryOperationalAdminChange = (
    newValue: SelectOption | undefined
  ) => {
    const selectedAdmin = newValue ? [newValue] : [];
    let selectedSecondaryAdmin = formValue?.secondaryOperationalAdmin || [];
    selectedSecondaryAdmin = operationalAdminList?.filter(
      (item: SelectOption) =>
        !selectedAdmin.find(
          (selectedItem: SelectOption) => selectedItem.value === item.value
        )
    );
    setFormValue({
      ...formValue,
      ...{
        primaryOperationalAdmin: selectedAdmin,
        secondaryOperationalAdmin: selectedSecondaryAdmin
      }
    });
  };

  const secondaryOperationalAdminChange = (newValue = []) => {
    const selectedAdmin = newValue || [];
    let selectedPrimaryAdmin = formValue?.primaryOperationalAdmin || [];

    const isDefaultAdminExist = !!operationalAdminList?.find(
      (item: SelectOption) =>
        defaultPrimaryOperationalAdmin?.find(
          (selectedItem: SelectOption) => selectedItem.value === item.value
        )
    );

    const isDefaultAdminSelectedAsPrimary = !!selectedPrimaryAdmin?.find(
      (item: SelectOption) => {
        return defaultPrimaryOperationalAdmin?.find(
          (innerItem: SelectOption) => {
            return innerItem?.value === item?.value;
          }
        );
      }
    );

    if (isDefaultAdminExist || isDefaultAdminSelectedAsPrimary) {
      selectedPrimaryAdmin = primaryOperationalAdminOptions?.filter(
        (item: SelectOption) =>
          !selectedAdmin?.find(
            (selectedItem: SelectOption) => selectedItem?.value === item?.value
          )
      );
    } else {
      selectedPrimaryAdmin = primaryOperationalAdminOptions?.filter(
        (item: SelectOption) =>
          !selectedAdmin?.find(
            (selectedItem: SelectOption) => selectedItem?.value === item?.value
          ) &&
          !defaultPrimaryOperationalAdmin?.find(
            (selectedItem: SelectOption) => selectedItem?.value === item?.value
          )
      );
    }

    setFormValue({
      ...formValue,
      ...{
        primaryOperationalAdmin: selectedPrimaryAdmin,
        secondaryOperationalAdmin: selectedAdmin
      }
    });
  };

  const getCompanyTypeGroupName = (companyTypeName: string) => {
    const companyTypeFound = allCompanyTypes?.find(
      (item: CompanyType) => item.companyTypeName === companyTypeName
    );
    return companyTypeFound ? companyTypeFound.groupName : '';
  };

  /* Parent Company start */
  const loadParentCompanyData = (inputValue: any, callback: Function) => {
    if (inputValue?.length > 2) {
      getParentCompanies(inputValue).then((result: any) => {
        callback(
          result.map((item: any) => {
            return { ...item, label: item.companyName, value: item.companyId };
          })
        );
      });
    } else {
      callback([]);
    }
  };

  const loadParentCompanyOptions = debounce(loadParentCompanyData, 600);

  const onParentCompanyChange = (selectedParentCompany: any) => {
    setFormValue({
      ...formValue,
      ...{
        parentCompany: selectedParentCompany,
        companyType: selectedParentCompany?.companyTypeName,
        companyTypeGroupName: getCompanyTypeGroupName(
          selectedParentCompany?.companyTypeName
        ),
        addressLine1: selectedParentCompany?.address1,
        addressLine2: selectedParentCompany?.address2,
        city: selectedParentCompany?.city,
        state: selectedParentCompany?.state,
        country: selectedParentCompany?.country,
        postalCode: selectedParentCompany?.postalCode,
        emailDomains:
          selectedParentCompany?.scRegCompanyAllowableDomains?.map(
            (item: any) => {
              return item?.domainName;
            }
          ) || [],
        pa:
          selectedParentCompany?.scRegCompanyPADetails?.map((item: any) => {
            return {
              email: item?.emailAddress,
              userId: item?.userId,
              firstName: item?.firstName,
              lastName: item?.lastName,
              value: item?.emailAddress,
              label: item?.emailAddress
            };
          }) || []
      }
    });
  };
  /* Parent Company end */

  const loadCecUsers = (inputValue: any, callback: Function) => {
    if (inputValue?.length > 2) {
      fetchCecUsers(inputValue).then((result: any) => {
        callback(
          result?.map((item: any) => {
            return { ...item, label: item.userId, value: item.userId };
          }) || []
        );
      });
    } else {
      callback([]);
    }
  };

  /* PAM select start */
  const loadPAMOptions = debounce(loadCecUsers, 600);
  const onPAMChange = (selectedUsers: any) => {
    setFormValue({
      ...formValue,
      ...{
        pam: selectedUsers
      }
    });
  };
  /* PAM select end */

  /* Sponsor select start */
  const loadSponsorOptions = debounce(loadCecUsers, 600);
  const onSponsorChange = (selectedUser: any) => {
    setFormValue({
      ...formValue,
      ...{
        sponsor: selectedUser
      }
    });
  };
  /* Sponsor select end */

  /* PA select start */
  const validateNewPA = async (step: number) => {
    setIsAddNewPAChecking(true);

    let newPAErrorList: any[] = [];
    let errorObject: any = {};

    const selectedNewPa = formValue['newPa'] || [];
    const paCheckResults = await Promise.all(
      selectedNewPa.map(async (item: SelectOption) => {
        let itemTemp = item;
        try {
          const checkResult = await checkNewPAExist(item?.email as string);
          itemTemp['isExist'] = checkResult;
          if (checkResult) {
            itemTemp['errorMessage'] =
              'PA email already in use. Please search through PA List';
          } else {
            if (itemTemp?.errorMessage) {
              delete itemTemp.errorMessage;
            }
          }
        } catch (err) {
          itemTemp['isExist'] = true;
          itemTemp['errorMessage'] = 'Something went wrong please try again';
        }
        return itemTemp;
      })
    );

    paCheckResults?.forEach((item: SelectOption, index: number) => {
      let paItemError: any = [];
      if (item.isExist) {
        paItemError['email'] = item?.errorMessage;
      }
      if (Object.keys(paItemError)?.length) {
        newPAErrorList[index] = paItemError;
      }
    });
    if (newPAErrorList?.length) {
      errorObject['newPa'] = newPAErrorList;
    }
    setIsAddNewPAChecking(false);
    setValidation({
      ...validation,
      ...{
        [steps[step]?.value]: {
          ...(validation?.[steps[step]?.value] || {}),
          ...errorObject
        }
      }
    });

    return Object.keys(errorObject).length === 0;
  };

  const loadPAOptions = async (
    inputValue: any,
    loadedOptions: any,
    { page }: any
  ) => {
    const emptyData = {
      options: [],
      hasMore: false,
      additional: {
        page: 1
      }
    };

    if (inputValue.length < 3) {
      return emptyData;
    }

    try {
      const result: any = await fetchSCIAMUsers(
        page,
        formValue?.companyName || '',
        formValue?.supplierSiteId || '',
        inputValue,
        formValue?.emailDomains || []
      );
      return {
        options:
          result?.data?.map((res: any) => ({
            firstName: res?.firstName,
            lastName: res?.lastName,
            email: res?.email,
            userId: res?.userId,
            label: res?.email,
            value: res?.email
          })) || [],
        hasMore: result?.totalPages ? result?.totalPages != page : false,
        additional: {
          page: page + 1
        }
      };
    } catch (err) {
      return emptyData;
    }
  };
  /// const loadPAOptions = debounce(loadPAUsers, 600);
  const onPAChange = (selectedUsers: any) => {
    setFormValue({
      ...formValue,
      ...{
        pa: selectedUsers
      }
    });
  };
  /* PA select end */

  const previewDialogClose = (newVal: boolean) => {
    setIsPreviewable(newVal);
  };

  const sponsorValue = formValue['sponsor'];

  const submitForm = () => {
    if (getFederatedCompanyDomainsStatus !== 'SUCCESS') {
      return;
    }

    const companySubmitData = {
      companyName: formValue['companyName'],
      vendorId: formValue['vendorId'],
      supplierSiteId: formValue['supplierSiteId'],
      vendorSiteCode: formValue['vendorSiteCode'],
      addressLine1: formValue['addressLine1'],
      addressLine2: formValue['addressLine2'],
      city: formValue['city'],
      country: formValue['country'],
      state: formValue['state'],
      postalCode: formValue['postalCode'],
      isCompanyAssociate: formValue['isCompanyAssociate'] || 'no',
      parentCompanyId: formValue['parentCompany']?.companyId,
      isMncaContractOnFileWithLegal: formValue['isMncaContractOnFileWithLegal'],
      companyType: formValue['companyType'],
      companyTypeId: formValue['companyTypeId'],
      dunsNumber: formValue['dunsNumber'],
      domainNames: formValue['emailDomains'],
      group: formValue['groupName'],
      pa:
        formValue['pa']?.map((item: SelectOption) => {
          return {
            userId: item.userId,
            email: item.email,
            firstName: item.firstName,
            lastName: item.lastName
          };
        }) || [],
      newPa:
        formValue['newPa']?.map((item: SelectOption) => {
          return {
            email: item.email,
            firstName: item.firstName,
            lastName: item.lastName
          };
        }) || [],
      pam: formValue['pam']?.map((item: SelectOption) => {
        return {
          userId: item.value,
          email: item.email,
          firstName: item.firstName,
          lastName: item.lastName
        };
      }),
      sponsor: sponsorValue
        ? {
            userId: sponsorValue?.value,
            email: sponsorValue?.email,
            firstName: sponsorValue?.firstName,
            lastName: sponsorValue?.lastName
          }
        : null,
      primaryOperationalAdmin: formValue['primaryOperationalAdmin']
        ? formValue['primaryOperationalAdmin']?.map((item: SelectOption) => {
            return {
              userId: item.value,
              email: item.email,
              firstName: item.firstName,
              lastName: item.lastName
            };
          })
        : [],
      secondaryOperationalAdmin: formValue['secondaryOperationalAdmin']
        ? formValue['secondaryOperationalAdmin']?.map((item: SelectOption) => {
            return {
              userId: item.value,
              email: item.email,
              firstName: item.firstName,
              lastName: item.lastName
            };
          })
        : [],
      countries: formValue['countries'] || [],
      customers: formValue['customers'] || [],
      organizations:
        formValue['organizations'] && formValue['organizations']?.length
          ? formValue['organizations']
          : [],
      isFederatedCompany: formValue['isFederatedCompany']
    };
    dispatch(companySubmit(companySubmitData));
  };

  const onclickStep = (activeStepValue: number, requestedStep: number) => {
    if (requestedStep <= activeStepValue) {
      setActiveStep(requestedStep);
    } else {
      // Validating all previous steps
      for (let i = 0; i < requestedStep; i++) {
        if (!checkStepValidity(i)) {
          setActiveStep(i);
          return;
        }
      }
      setActiveStep(requestedStep);
    }
  };

  const newPAAddOpen = () => {
    setFormValue({
      ...formValue,
      ...{ newPa: [{ firstName: '', lastName: '', email: '' }] }
    });
    setIsAddNewPAOpen(true);
  };
  const newPAAddClose = () => {
    setFormValue({ ...formValue, ...{ newPa: [] } });
    setIsAddNewPAOpen(false);
  };

  const handleInputChange = (inputValue: any, actionMeta: any) => {
    if (actionMeta.action === 'input-change') {
      let sanitizedValue = inputValue
        .replace(/[^a-zA-Z0-9_ -]/g, '')
        .toUpperCase();
      sanitizedValue = sanitizedValue.replace(/\s+/g, ' ');
      setInputValue(sanitizedValue);
    }
  };

  return (
    <div className="container">
      {postCompanyStatus === 'PENDING' && (
        <div className="center-holder-wrap">
          <Loader />
        </div>
      )}

      {isAddNewPAChecking && (
        <TransparentFullScreenLoader title={'Validating'} />
      )}

      {(!postCompanyStatus || postCompanyStatus !== 'SUCCESS') && (
        <>
          <CompanyFormPreview
            handleNext={() => {
              submitForm();
            }}
            open={isPreviewable}
            companyDetail={formValue}
            onChange={previewDialogClose}
            loading={postCompanyStatus === 'PENDING'}
          />

          <div className="section">
            <div
              className="panel panel--bordered"
              style={{ marginBottom: '100px', overflow: 'visible' }}
            >
              <div style={{ width: '100%' }}>
                {activeStep !== steps.length && (
                  <>
                    <div className="row">
                      {activeStep != 0 && (
                        <div className="col-md-12 border">
                          <div className="flex flex-left">
                            <div className="subheader ">
                              <b>Company Name:</b>{' '}
                              {formValue?.selectedCompany?.companyName} - (
                              {formValue?.selectedCompany?.vendorId})
                            </div>
                          </div>
                        </div>
                      )}
                    </div>
                    {activeStep == 3 && (
                      <div
                        className="base-margin"
                        style={{ display: 'flex', flexDirection: 'row' }}
                      >
                        <button onClick={handleBack} className="btn btn--ghost">
                          Back
                        </button>
                        <div style={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                          <button
                            className="btn btn--ghost"
                            onClick={handleSkip}
                          >
                            Skip
                          </button>
                        )}

                        <button
                          onClick={() =>
                            handleNext(activeStep === steps.length - 1)
                          }
                          className="btn btn--ghost"
                          // disabled={!checkStepValidity(activeStep)}
                          disabled={isAddNewPAChecking}
                        >
                          {' '}
                          {activeStep === steps.length - 1
                            ? 'Preview & Finish'
                            : 'Next'}
                        </button>
                      </div>
                    )}
                    <div className="row">
                      <div className={'col-md-12'}>
                        <div className="steps no-margin-bottom">
                          {steps.map(({ label }: Step, index) => {
                            let selClass = '';
                            if (index === activeStep) {
                              selClass = 'active';
                            } else if (index <= completedSteps) {
                              selClass = 'visited';
                            }

                            const clickableEvent = selClass
                              ? () => {
                                  onclickStep(activeStep, index);
                                }
                              : undefined;

                            return (
                              <div
                                key={`${label}${index}`}
                                className={`step ${selClass}`}
                              >
                                <div
                                  className="step__icon"
                                  onClick={clickableEvent}
                                >
                                  {index + 1}
                                </div>
                                <div
                                  className="step__label"
                                  onClick={clickableEvent}
                                >
                                  {label}
                                </div>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </>
                )}
                <div className=" ">
                  <React.Fragment>
                    <div
                      className="half-padding-top half-padding-bottom"
                      style={{
                        display:
                          steps[activeStep]?.value === 'search' ? '' : 'none'
                      }}
                    >
                      <SelectCompaniesTable
                        value={formValue?.selectedCompany}
                        onSelect={selectCompany}
                      />
                    </div>
                    <>
                      <div
                        className="half-padding-top half-padding-bottom"
                        style={{
                          display:
                            steps && steps[activeStep]?.value === 'associate'
                              ? ''
                              : 'none'
                        }}
                      >
                        <div className="flex">
                          <div style={{ margin: 'auto' }}>
                            <div className="flex ">
                              <div className="form-group form-group--inline form-group--compressed">
                                <b>
                                  Would you like to associate this company with
                                  an existing one?
                                </b>
                              </div>
                              <div className="form-group form-group--inline form-group--compressed">
                                <label className="radio qtr-margin-left">
                                  <input
                                    type="radio"
                                    value="yes"
                                    checked={
                                      formValue?.isCompanyAssociate === 'yes'
                                    }
                                    onChange={companyAssociateChange}
                                    name="radio-inline"
                                  />
                                  <span className="radio__input"></span>
                                  <span className="radio__label">Yes</span>
                                </label>
                              </div>
                              <div className="form-group form-group--inline form-group--compressed">
                                <label className="radio">
                                  <input
                                    type="radio"
                                    value="no"
                                    checked={
                                      formValue?.isCompanyAssociate === 'no'
                                    }
                                    onChange={companyAssociateChange}
                                  />
                                  <span className="radio__input"></span>
                                  <span className="radio__label">No</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                        {formValue?.isCompanyAssociate === 'yes' ? (
                          <div className="flex flex-center form-group">
                            <div className="form-group__text base-margin-top base-margin-top">
                              <label
                                htmlFor="input-type-company-name"
                                className="text-weight-700"
                              >
                                Search Company
                              </label>
                              <div style={{ width: '250px' }}>
                                <AsyncSelect
                                  isClearable
                                  loadOptions={loadParentCompanyOptions}
                                  onChange={onParentCompanyChange}
                                />

                                {submittedSteps['associate'] &&
                                  validation &&
                                  validation['associate']?.parentCompany && (
                                    <span className="text-danger text-size-12">
                                      {validation['associate']?.parentCompany}
                                    </span>
                                  )}
                              </div>
                            </div>
                          </div>
                        ) : null}

                        {formValue?.parentCompany?.companyName ? (
                          <div className="row container d-flex align-items-center justify-content-center base-margin-top">
                            <div className="col-md-6 mx-auto">
                              <div className="card">
                                <div className="card-header">
                                  <h5 className="text-size-16 text-weight-700 text-primary">
                                    Parent Company Details
                                  </h5>
                                </div>

                                <div className="card-body">
                                  <div className="row qtr-margin-top">
                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                                      <div className="record-info">
                                        <span className="record-label">
                                          Company Name
                                        </span>
                                        <div>
                                          <span className="record-value">
                                            {formValue?.parentCompany
                                              ?.companyName || '-'}
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                                      <div className="record-info">
                                        <span className="record-label">
                                          Vendor Id
                                        </span>
                                        <div>
                                          <span className="record-value">
                                            {formValue?.parentCompany
                                              ?.vendorId || '-'}
                                          </span>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4">
                                      <div className="record-info">
                                        <span className="record-label">
                                          Company Type
                                        </span>
                                        <div>
                                          <span className="record-value">
                                            {formValue?.parentCompany
                                              ?.companyTypeName || '-'}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        ) : null}
                      </div>

                      {steps && steps[activeStep]?.value === 'contract' && (
                        <>
                          <div className="flex half-padding-top half-padding-bottom">
                            <div style={{ margin: 'auto' }}>
                              <div
                                className="subheader text-weight-700 text-size-14"
                                style={{ textTransform: 'none' }}
                              >
                                Is MNCA contract on file with legal?
                              </div>
                              <div className="form-group">
                                <label className="checkbox">
                                  <input
                                    name="isMncaContractOnFileWithLegal"
                                    checked={
                                      !!formValue?.isMncaContractOnFileWithLegal
                                    }
                                    onChange={onChangeCheckBox}
                                    type="checkbox"
                                  />
                                  <span className="checkbox__input"></span>
                                  <span className="checkbox__label">
                                    Yes, I Certify that this {" company's "}{' '}
                                    contract is on file with cisco legal
                                    department.
                                  </span>
                                </label>
                              </div>
                              {submittedSteps['contract'] &&
                                validation &&
                                validation['contract']
                                  ?.isMncaContractOnFileWithLegal && (
                                  <div className="alert alert--warning">
                                    <div className="alert__icon icon-warning-outline"></div>
                                    <div className="alert__message">
                                      {
                                        validation['contract']
                                          ?.isMncaContractOnFileWithLegal
                                      }
                                    </div>
                                  </div>
                                )}
                            </div>
                          </div>
                        </>
                      )}

                      {steps && steps[activeStep]?.value === 'companyDetails' && (
                        <>
                          <div className="card half-padding-top half-padding-bottom">
                            <div className="card-header">
                              <h5 className="text-primary">Company Details</h5>
                            </div>
                            <div className="card-body">
                              <div className="">
                                <div className="row">
                                  <div className="col-md-3 border">
                                    <div className="form-group base-margin-bottom">
                                      <div className="form-group__text">
                                        <label
                                          htmlFor="input-type-company-name"
                                          className="text-weight-700 "
                                        >
                                          Company Name
                                        </label>
                                        <div className="form-value-holder">
                                          {formValue?.companyName || '-'}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group base-margin-bottom">
                                      <div className="form-group__text">
                                        <label
                                          htmlFor="input-type-company-name"
                                          className="text-weight-700 "
                                        >
                                          Vendor Id
                                        </label>
                                        <div className="form-value-holder">
                                          {formValue?.selectedCompany
                                            ?.vendorId || '-'}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group base-margin-bottom">
                                      <div className="form-group__text">
                                        <label
                                          htmlFor="input-type-company-name"
                                          className="text-weight-700 "
                                        >
                                          Site Id
                                        </label>
                                        <div className="form-value-holder">
                                          {formValue?.supplierSiteId || '-'}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className={`form-group`}>
                                      <div className="form-group__text">
                                        <label
                                          htmlFor="input-dunsNumber"
                                          className="text-weight-700"
                                        >
                                          DUNS Number
                                        </label>

                                        <div className="form-value-holder">
                                          {formValue?.dunsNumber || '-'}
                                        </div>
                                      </div>
                                      {submittedSteps['companyDetails'] &&
                                        validation &&
                                        validation['companyDetails']
                                          ?.dunsNumber && (
                                          <span className="text-danger text-size-12">
                                            {
                                              validation['companyDetails']
                                                ?.dunsNumber
                                            }
                                          </span>
                                        )}
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group base-margin-bottom">
                                      <div className="form-group__text">
                                        <label
                                          htmlFor="input-addressLine1"
                                          className="text-weight-700 "
                                        >
                                          Address Line 1
                                        </label>

                                        <div className="form-value-holder">
                                          {formValue?.addressLine1 || '-'}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group base-margin-bottom">
                                      <div className="form-group__text">
                                        <label
                                          htmlFor="input-addressLine2"
                                          className="text-weight-700 "
                                        >
                                          Address Line 2
                                        </label>
                                        <div className="form-value-holder">
                                          {formValue?.addressLine2 || '-'}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div className="col-md-3">
                                    <div className="form-group base-margin-bottom">
                                      <div className="form-group__text">
                                        <label
                                          htmlFor="input-city"
                                          className="text-weight-700 "
                                        >
                                          City
                                        </label>
                                        <div className="form-value-holder">
                                          {formValue?.city || '-'}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className="form-group base-margin-bottom">
                                      <div className="form-group__text">
                                        <label
                                          htmlFor="input-state"
                                          className="text-weight-700 "
                                        >
                                          State
                                        </label>
                                        <div className="form-value-holder">
                                          {formValue?.state || '-'}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className="form-group base-margin-bottom">
                                      <div className="form-group__text">
                                        <label
                                          htmlFor="input-country"
                                          className="text-weight-700 "
                                        >
                                          Country
                                        </label>
                                        <div className="form-value-holder">
                                          {formValue?.country || '-'}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className="form-group base-margin-bottom">
                                      <div className="form-group__text">
                                        <label
                                          htmlFor="input-postalCode"
                                          className="text-weight-700 "
                                        >
                                          Zip/ Postal Code
                                        </label>

                                        <div className="form-value-holder">
                                          {formValue?.postalCode || '-'}
                                        </div>
                                      </div>
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className={`form-group`}>
                                      <div className="form-group__text">
                                        <label
                                          htmlFor="input-type-company-name"
                                          className="text-weight-700"
                                        >
                                          Email Domains{' '}
                                          <span className="required"></span>{' '}
                                          <span
                                            className="text-weight-400"
                                            style={{
                                              position: 'relative'
                                            }}
                                          >
                                            <span className="text-size-12">
                                              (Example : @example.com){' '}
                                            </span>
                                            <span
                                              className="icon-info-outline text-info"
                                              data-tooltip-id={`tooltip-domain`}
                                              style={{ cursor: 'pointer' }}
                                            >
                                              <Tooltip id={`tooltip-domain`}>
                                                <span style={{ zIndex: '111' }}>
                                                  Type domain name and press
                                                  enter (example : @example.com){' '}
                                                </span>
                                              </Tooltip>
                                            </span>
                                          </span>
                                        </label>

                                        <DomainSelect
                                          values={
                                            formValue?.emailDomains?.map(
                                              (item: any) => {
                                                return {
                                                  value: item,
                                                  label: item
                                                };
                                              }
                                            ) || []
                                          }
                                          onChange={(
                                            newValue: SelectOption[]
                                          ) => onDomainChange(newValue)}
                                        />
                                      </div>

                                      {submittedSteps['companyDetails'] &&
                                        validation &&
                                        validation['companyDetails']
                                          ?.emailDomains && (
                                          <span className="text-danger text-size-12">
                                            {
                                              validation['companyDetails']
                                                ?.emailDomains
                                            }
                                          </span>
                                        )}
                                    </div>
                                  </div>

                                  <div className="col-md-3">
                                    <div className="form-group half-margin-bottom">
                                      <div className="form-group__text">
                                        <label
                                          htmlFor="select-companyType"
                                          className=" text-weight-700"
                                        >
                                          <strong>Group Name</strong>{' '}
                                          <span
                                            className="text-weight-400"
                                            style={{
                                              position: 'relative'
                                            }}
                                          >
                                            <span
                                              className="icon-info-outline text-info"
                                              data-tooltip-id={`tooltip-groupName`}
                                              style={{ cursor: 'pointer' }}
                                            >
                                              <Tooltip id={`tooltip-groupName`}>
                                                <>
                                                  <div
                                                    style={{ zIndex: '111' }}
                                                  >
                                                    You may either select from
                                                    the list by clicking in the
                                                    text box or type a new group
                                                    name followed by clicking on
                                                    the create option.
                                                  </div>

                                                  <div
                                                    style={{ zIndex: '111' }}
                                                  >
                                                    Only Alphanumeric character
                                                    is allowed with underscore
                                                    and dash as special
                                                    characters
                                                  </div>
                                                </>
                                              </Tooltip>
                                            </span>
                                          </span>
                                        </label>

                                        <div className="form-group__text">
                                          <CreatableSelect
                                            name="groupName"
                                            isClearable
                                            inputValue={inputValue}
                                            onInputChange={handleInputChange}
                                            options={
                                              groups?.map((item: any) => {
                                                return {
                                                  value: item,
                                                  label: item
                                                };
                                              }) || []
                                            }
                                            value={
                                              formValue?.groupName
                                                ? {
                                                    value: formValue?.groupName,
                                                    label: formValue?.groupName
                                                  }
                                                : undefined
                                            }
                                            onChange={(
                                              newValue: SelectOption
                                            ) => {
                                              const isNew =
                                                newValue?.__isNew__ as any;
                                              let value = newValue?.value;
                                              if (isNew) {
                                                value = value
                                                  .replace(
                                                    /[^a-zA-Z0-9_ -]/g,
                                                    ''
                                                  )
                                                  .toUpperCase();
                                                value = value
                                                  .replace(/\s+/g, ' ')
                                                  .trim();
                                              }
                                              setInputValue('');
                                              onSelectorChange(
                                                value,
                                                'groupName'
                                              );
                                            }}
                                          />
                                        </div>
                                        {submittedSteps['companyDetails'] &&
                                          validation &&
                                          validation['companyDetails']
                                            ?.groupName && (
                                            <span className="text-danger text-size-12">
                                              {
                                                validation['companyDetails']
                                                  ?.groupName
                                              }
                                            </span>
                                          )}
                                      </div>
                                    </div>
                                  </div>
                                </div>

                                <div className="row">
                                  <div className="col-md-3">
                                    <div className="form-group half-margin-bottom half-margin-top">
                                      <div className="form-group__text">
                                        <label
                                          htmlFor="select-companyType"
                                          className=" text-weight-700 required"
                                        >
                                          <strong>Company Type</strong>
                                        </label>

                                        {formValue?.parentCompany ? (
                                          <div className="qtr-padding">
                                            {formValue?.companyType}
                                          </div>
                                        ) : null}

                                        {!formValue?.parentCompany ? (
                                          <>
                                            <div className="form-group__text">
                                              <Select
                                                isClearable
                                                value={
                                                  formValue?.companyType
                                                    ? {
                                                        value:
                                                          formValue?.companyType,
                                                        label:
                                                          formValue?.companyType
                                                      }
                                                    : undefined
                                                }
                                                name="companyType"
                                                options={
                                                  allCompanyTypes?.length
                                                    ? allCompanyTypes.map(
                                                        (item: CompanyType) => {
                                                          return {
                                                            value: item.id,
                                                            label:
                                                              item.companyTypeName,
                                                            data: item
                                                          };
                                                        }
                                                      )
                                                    : []
                                                }
                                                className="basic-multi-select"
                                                classNamePrefix="select"
                                                onChange={(
                                                  selectedValues:
                                                    | SelectOption
                                                    | undefined
                                                ) =>
                                                  onCompanyTypeChange(
                                                    selectedValues
                                                  )
                                                }
                                              />
                                            </div>
                                            {submittedSteps['companyDetails'] &&
                                              validation &&
                                              validation['companyDetails']
                                                ?.companyType && (
                                                <span className="text-danger text-size-12">
                                                  {
                                                    validation['companyDetails']
                                                      ?.companyType
                                                  }
                                                </span>
                                              )}
                                          </>
                                        ) : null}

                                        {submittedSteps['companyDetails'] &&
                                        validation['companyDetails']
                                          ?.companyTypeGroupName ? (
                                          <div className="alert alert--warning">
                                            <div className="alert__icon icon-warning-outline"></div>
                                            <div className="alert__message">
                                              {
                                                validation['companyDetails']
                                                  ?.companyTypeGroupName
                                              }
                                            </div>
                                          </div>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>

                                  {formValue?.isCountryShow ? (
                                    <>
                                      <div className="col-md-3 half-margin-bottom half-margin-top">
                                        <div className={`form-group`}>
                                          <div className="form-group__text">
                                            <label
                                              htmlFor="input-type-countries-name"
                                              className={
                                                formValue?.isCountryMandatory
                                                  ? 'required text-weight-700'
                                                  : 'text-weight-700'
                                              }
                                            >
                                              Countries
                                            </label>

                                            <Select
                                              value={
                                                formValue?.countries?.map(
                                                  (item: any) => {
                                                    return {
                                                      value: item,
                                                      label: item
                                                    };
                                                  }
                                                ) || []
                                              }
                                              isMulti
                                              name="countries"
                                              options={
                                                countries?.length
                                                  ? countries.map(
                                                      (item: any) => {
                                                        return {
                                                          value: item,
                                                          label: item
                                                        };
                                                      }
                                                    )
                                                  : []
                                              }
                                              className="basic-multi-select"
                                              classNamePrefix="select"
                                              onChange={onCountriesChange}
                                            />

                                            {submittedSteps['companyDetails'] &&
                                              validation &&
                                              validation['companyDetails']
                                                ?.countries && (
                                                <span className="text-danger text-size-12">
                                                  {
                                                    validation['companyDetails']
                                                      ?.countries
                                                  }
                                                </span>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : null}

                                  {formValue?.isCustomerShow ? (
                                    <>
                                      <div className="col-md-3 half-margin-bottom half-margin-top">
                                        <div className={`form-group`}>
                                          <div className="form-group__text">
                                            <label
                                              htmlFor="input-type-customers-name"
                                              className={
                                                formValue?.isCustomerMandatory
                                                  ? 'required text-weight-700'
                                                  : 'text-weight-700'
                                              }
                                            >
                                              Customers
                                            </label>

                                            <Select
                                              value={
                                                formValue?.customers?.map(
                                                  (item: any) => {
                                                    return {
                                                      value: item,
                                                      label: item
                                                    };
                                                  }
                                                ) || []
                                              }
                                              isMulti
                                              name="customers"
                                              options={
                                                customers?.length
                                                  ? customers.map(
                                                      (item: any) => {
                                                        return {
                                                          value: item,
                                                          label: item
                                                        };
                                                      }
                                                    )
                                                  : []
                                              }
                                              className="basic-multi-select"
                                              classNamePrefix="select"
                                              onChange={(selectedValues: any) =>
                                                onSelectorChange(
                                                  selectedValues?.map(
                                                    (item: any) => item.value
                                                  ) || [],
                                                  'customers'
                                                )
                                              }
                                            />

                                            {submittedSteps['companyDetails'] &&
                                              validation &&
                                              validation['companyDetails']
                                                ?.customers && (
                                                <span className="text-danger text-size-12">
                                                  {
                                                    validation['companyDetails']
                                                      ?.customers
                                                  }
                                                </span>
                                              )}
                                          </div>
                                        </div>
                                      </div>
                                    </>
                                  ) : null}

                                  {formValue?.isOrgShow ? (
                                    <div className="col-md-3 half-margin-bottom half-margin-top">
                                      <div className="form-group">
                                        <div className="form-group__text">
                                          <label
                                            htmlFor="select-organizations"
                                            className={
                                              formValue?.isOrgMandatory
                                                ? 'required text-weight-700'
                                                : 'text-weight-700'
                                            }
                                          >
                                            Company Orgs
                                          </label>
                                          <div>
                                            <Select
                                              value={
                                                formValue?.organizations?.map(
                                                  (item: any) => {
                                                    return {
                                                      value: item,
                                                      label: item
                                                    };
                                                  }
                                                ) || []
                                              }
                                              isMulti
                                              name="organizations"
                                              options={
                                                organizationsOptions?.length
                                                  ? organizationsOptions.map(
                                                      (item: any) => {
                                                        return {
                                                          value:
                                                            item?.organizationCode,
                                                          label: `${item?.organizationName} (${item?.organizationCode})`
                                                        };
                                                      }
                                                    )
                                                  : []
                                              }
                                              className="basic-multi-select"
                                              classNamePrefix="select"
                                              onChange={organizationChange}
                                            />
                                          </div>
                                          {submittedSteps['companyDetails'] &&
                                            validation &&
                                            validation['companyDetails']
                                              ?.organizations && (
                                              <span className="text-danger text-size-12">
                                                {
                                                  validation['companyDetails']
                                                    ?.organizations
                                                }
                                              </span>
                                            )}
                                        </div>
                                      </div>
                                    </div>
                                  ) : null}
                                </div>
                              </div>

                              {formValue?.parentCompany?.companyName ? (
                                <div className="card">
                                  <div className="card-header">
                                    <h5 className="text-size-16 text-weight-700 text-primary">
                                      Parent Company Details
                                    </h5>
                                  </div>

                                  <div className="card-body">
                                    <div className="row base-margin-top">
                                      <div className="col-lg-3">
                                        <div className="form-group">
                                          <div className="form-group__text">
                                            <label htmlFor="input-sr-stacked-1">
                                              <b>Company Name</b>
                                            </label>
                                            <div className="qtr-padding">
                                              {
                                                formValue?.parentCompany
                                                  ?.companyName
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-lg-3">
                                        <div className="form-group">
                                          <div className="form-group__text">
                                            <label htmlFor="input-sr-stacked-2">
                                              <b>Vendor Id</b>
                                            </label>
                                            <div className="qtr-padding">
                                              {
                                                formValue?.parentCompany
                                                  ?.vendorId
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>

                                      <div className="col-lg-3">
                                        <div className="form-group">
                                          <div className="form-group__text">
                                            <label htmlFor="input-sr-stacked-2">
                                              <b>Company Type</b>
                                            </label>
                                            <div className="qtr-padding">
                                              {
                                                formValue?.parentCompany
                                                  ?.companyTypeName
                                              }
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              ) : null}
                            </div>
                          </div>

                          <div className="card half-margin-top  half-margin-bottom">
                            <div className="card-header">
                              <h5 className="text-primary">Admin Details</h5>
                            </div>
                            <div className="card-body">
                              <div className="row base-margin-bottom">
                                <div className="col-md-4">
                                  <div className="form-group">
                                    <div className="form-group__text">
                                      <label
                                        htmlFor="input-type-company-search-name"
                                        className="text-weight-700"
                                      >
                                        Designated Partner Administrators
                                        {!formValue?.isFederatedCompany ? (
                                          <span className="required"></span>
                                        ) : null}{' '}
                                        <span
                                          className="text-weight-400"
                                          style={{
                                            position: 'relative'
                                          }}
                                        >
                                          <span
                                            className="icon-info-outline text-info"
                                            data-tooltip-id={`tooltip-pam`}
                                            style={{ cursor: 'pointer' }}
                                          >
                                            <Tooltip id={`tooltip-pam`}>
                                              <span style={{ zIndex: '111' }}>
                                                Search by first name,last
                                                name,email and user ID.{' '}
                                              </span>
                                            </Tooltip>
                                          </span>
                                        </span>
                                      </label>
                                      <div>
                                        <UserPaginateSelect
                                          isDisabled={
                                            !formValue?.emailDomains?.length
                                          }
                                          id="paInput"
                                          value={formValue?.pa}
                                          name="pa"
                                          isMulti
                                          placeholder={'Search PA....'}
                                          loadOptions={loadPAOptions}
                                          onChange={onPAChange}
                                          additional={{
                                            page: 1
                                          }}
                                        />
                                      </div>
                                    </div>
                                    {submittedSteps['companyDetails'] &&
                                      validation &&
                                      validation['companyDetails']?.pa && (
                                        <span className="text-danger text-size-12">
                                          {validation['companyDetails']?.pa}
                                        </span>
                                      )}
                                  </div>
                                </div>
                                <div className="col-md-8">
                                  <div className="base-margin-bottom">
                                    <div className="half-padding-top half-padding-bottom">
                                      If PA user not found, Please add new PA
                                      here{' '}
                                      {!isAddNewPAOpen ? (
                                        <button
                                          type="button"
                                          className="btn btn--small"
                                          onClick={newPAAddOpen}
                                        >
                                          Add PA
                                        </button>
                                      ) : null}
                                      {isAddNewPAOpen ? (
                                        <button
                                          type="button"
                                          className="btn btn--small"
                                          onClick={newPAAddClose}
                                        >
                                          Remove all new PA
                                        </button>
                                      ) : null}
                                    </div>
                                    {isAddNewPAOpen && (
                                      <>
                                        <PAUserEdit
                                          paUserList={formValue?.newPa}
                                          onChange={(paUserList: any) => {
                                            onPaListChange(paUserList);
                                          }}
                                          validation={
                                            submittedSteps['companyDetails'] &&
                                            validation &&
                                            validation['companyDetails']?.newPa
                                              ? validation['companyDetails']
                                                  ?.newPa
                                              : []
                                          }
                                        />
                                      </>
                                    )}
                                  </div>
                                </div>
                              </div>

                              {submittedSteps['companyDetails'] &&
                              validation['companyDetails']?.allPa ? (
                                <div className="alert alert--warning">
                                  <div className="alert__icon icon-warning-outline"></div>
                                  <div className="alert__message">
                                    {validation['companyDetails']?.allPa}
                                  </div>
                                </div>
                              ) : null}

                              <div className="">
                                <div className="row">
                                  <div className="col-md-4">
                                    <div className={`form-group`}>
                                      <div className="form-group__text">
                                        <label
                                          htmlFor="input-type-company-search-name"
                                          className="text-weight-700"
                                        >
                                          Partner Account Managers
                                          <span className="required"></span>{' '}
                                          <span
                                            className="text-weight-400"
                                            style={{
                                              position: 'relative'
                                            }}
                                          >
                                            <span
                                              className="icon-info-outline text-info"
                                              data-tooltip-id={`tooltip-pam`}
                                              style={{ cursor: 'pointer' }}
                                            >
                                              <Tooltip id={`tooltip-pam`}>
                                                <span style={{ zIndex: '111' }}>
                                                  Search by first name,last
                                                  name,email and user ID.{' '}
                                                </span>
                                              </Tooltip>
                                            </span>
                                          </span>
                                        </label>
                                        <div>
                                          <UserSelect
                                            value={formValue?.pam}
                                            controlShouldRenderValue={false}
                                            name="pam"
                                            isMulti
                                            cacheOptions
                                            placeholder={'Search PAM....'}
                                            loadOptions={loadPAMOptions}
                                            onChange={onPAMChange}
                                          />
                                        </div>
                                      </div>
                                      {submittedSteps['companyDetails'] &&
                                        validation &&
                                        validation['companyDetails']?.pam && (
                                          <span className="text-danger text-size-12">
                                            {validation['companyDetails']?.pam}
                                          </span>
                                        )}
                                    </div>
                                  </div>
                                  <div className="col-md-4">
                                    <div className={`form-group`}>
                                      <div className="form-group__text">
                                        <label
                                          htmlFor="input-type-company-name"
                                          className="text-weight-700"
                                        >
                                          Cisco Sponsor
                                          <span className="required"></span>{' '}
                                          <span
                                            className="text-weight-400"
                                            style={{
                                              position: 'relative'
                                            }}
                                          >
                                            <span
                                              className="icon-info-outline text-info"
                                              data-tooltip-id={`tooltip-sponsor`}
                                              style={{ cursor: 'pointer' }}
                                            >
                                              <Tooltip id={`tooltip-sponsor`}>
                                                <span style={{ zIndex: '111' }}>
                                                  Search by first name,last
                                                  name,email and user ID.{' '}
                                                </span>
                                              </Tooltip>
                                            </span>
                                          </span>
                                        </label>
                                        <div>
                                          <UserSelect
                                            isClearable
                                            controlShouldRenderValue={false}
                                            value={
                                              formValue?.sponsor
                                                ? (formValue?.sponsor as SelectOption)
                                                : null
                                            }
                                            name="sponsor"
                                            cacheOptions
                                            placeholder={'Search Sponsor....'}
                                            loadOptions={loadSponsorOptions}
                                            onChange={onSponsorChange}
                                          />
                                        </div>
                                      </div>
                                      {submittedSteps['companyDetails'] &&
                                        validation &&
                                        validation['companyDetails']
                                          ?.sponsor && (
                                          <span className="text-danger text-size-12">
                                            {
                                              validation['companyDetails']
                                                ?.sponsor
                                            }
                                          </span>
                                        )}
                                    </div>
                                  </div>
                                </div>

                                {validation &&
                                  validation['companyDetails']
                                    ?.operationalAdmin && (
                                    <div className="col-md-12">
                                      <div className="alert alert--warning">
                                        <div className="alert__icon icon-warning-outline"></div>
                                        <div className="alert__message">
                                          {
                                            validation['companyDetails']
                                              ?.operationalAdmin?.message
                                          }
                                          {' - ' +
                                            validation[
                                              'companyDetails'
                                            ]?.operationalAdmin?.users
                                              ?.map(
                                                (item: SelectOption) =>
                                                  item.value
                                              )
                                              .join(' ,')}
                                        </div>
                                      </div>
                                    </div>
                                  )}
                              </div>
                            </div>
                          </div>

                          <div className="card base-margin-bottom">
                            <div className="card-header">
                              <h5 className="text-primary">
                                Operational Admin Details
                              </h5>
                            </div>
                            <div className="card-body">
                              <div className="row half-padding-top half-padding-bottom">
                                <div className="col-md-6">
                                  <div className="form-group">
                                    <div className="form-group__text">
                                      <label htmlFor="select-primary-org">
                                        Primary Operational Admin{' '}
                                        <span className="required"></span>{' '}
                                      </label>
                                      <div>
                                        <Select
                                          isDisabled={
                                            defaultPrimaryOperationalAdmin?.length
                                          }
                                          value={
                                            formValue?.primaryOperationalAdmin
                                              ?.length
                                              ? formValue
                                                  ?.primaryOperationalAdmin[0]
                                              : undefined
                                          }
                                          name="primaryOperationalAdmin"
                                          isClearable
                                          options={
                                            primaryOperationalAdminOptions?.length
                                              ? primaryOperationalAdminOptions
                                              : []
                                          }
                                          components={{
                                            Option: UserSelectOption
                                          }}
                                          className="basic-multi-select"
                                          classNamePrefix="select"
                                          onChange={
                                            primaryOperationalAdminChange
                                          }
                                          isLoading={
                                            getOperationalAdminStatus ===
                                            'PENDING'
                                          }
                                        />
                                      </div>
                                    </div>
                                    {submittedSteps['companyDetails'] &&
                                      validation &&
                                      validation['companyDetails']
                                        ?.primaryOperationalAdmin && (
                                        <span className="text-danger text-size-12">
                                          {
                                            validation['companyDetails']
                                              ?.primaryOperationalAdmin
                                          }
                                        </span>
                                      )}
                                  </div>
                                </div>
                                <div className="col-md-6 ">
                                  <div className="form-group">
                                    <div className="form-group__text">
                                      <label htmlFor="select-additionalOrganization">
                                        Secondary Operational Admin
                                      </label>
                                      <div>
                                        <div className="edit-form-value-holder">
                                          {formValue?.secondaryOperationalAdmin
                                            ?.map((item: any) => item.value)
                                            .join(', ')}
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </>
                      )}
                    </>
                    {((activeStep === 0 && formValue?.companyName) ||
                      (activeStep != 0 && activeStep != steps.length)) && (
                      <div
                        className="base-margin-right base-margin-left"
                        style={{ display: 'flex', flexDirection: 'row' }}
                      >
                        <button
                          disabled={activeStep === 0}
                          onClick={handleBack}
                          className="btn btn--ghost"
                        >
                          Back
                        </button>

                        <div style={{ flex: '1 1 auto' }} />
                        {isStepOptional(activeStep) && (
                          <button
                            className="btn btn--ghost"
                            onClick={handleSkip}
                          >
                            Skip
                          </button>
                        )}

                        <button
                          onClick={() =>
                            handleNext(activeStep === steps.length - 1)
                          }
                          className="btn btn--ghost"
                          //  disabled={!checkStepValidity(activeStep)}
                        >
                          {' '}
                          {activeStep === steps.length - 1
                            ? 'Preview & Finish'
                            : 'Next'}
                        </button>
                      </div>
                    )}
                  </React.Fragment>
                </div>
              </div>
            </div>
          </div>
        </>
      )}

      {postCompanyStatus === 'SUCCESS' && (
        <React.Fragment>
          <div className="">
            <div className="container">
              <div className="section">
                <div className="text-center">
                  <h1 className="text-success">
                    <span className="icon-check-outline"></span>
                  </h1>
                  <h4 className="text-size-16">
                    Successfully on-boarded the company site.
                  </h4>
                  <div className="dbl-margin-top">
                    <Link to="/" className="btn btn--ghost">
                      Please return to home page.
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};
export default CompanyOnboard;
